import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { App as CapacitorApp } from "@capacitor/app";
import Login from "../auth/login/page.jsx";
import VerifyOtp from "../auth/verify-otp/page.jsx";
import CircleType from "../secure/onboarding/circle-type/page";
import CircleInfo from "../secure/onboarding/circle-info/page";
import CircleInterests from "../secure/onboarding/circle-interests/page";
import Circles from "../secure/circles/page";
import CircleDetail from "../secure/circle-details/page";
import { AnimatePresence } from "framer-motion";
import AddParticipants from "../secure/circle-details/components/participants/add-participants.jsx";
import CircleParticipants from "../secure/circle-details/components/participants/circle-participants.jsx";
import MobileAddParticipants from "../secure/circle-details/components/participants/mobile-add-participants.jsx";
import AddExpense from "../secure/circle-details/components/add-expenses/page.jsx";
import Participants from "../secure/circle-details/components/add-expenses/participants.jsx";
import Payee from "../secure/circle-details/components/add-expenses/payee.jsx";
import Panel from "../shared/components/panel/page.jsx";
import Profile from "../secure/circles/settings/profile/page.jsx";
import AddExpenseDetail from "../secure/circle-details/components/add-expenses/add-expense-detail.jsx";
import ExpenseDetail from "../secure/circle-details/components/expense-detail/page.jsx";
import AddItinerary from "../secure/circle-details/components/add-itinerary/page.jsx";
import AddItineraryDetail from "../secure/circle-details/components/add-itinerary/add-itinerary-detail.jsx";
// import Origin from "../secure/circle-details/components/add-itinerary/origin.jsx";
// import Destination from "../secure/circle-details/components/add-itinerary/destination.jsx";
// import Summary from "../secure/circle-details/components/add-itinerary/summary.jsx";
import { useMutation } from "@tanstack/react-query";
import { refreshTokenMutation } from "../shared/services/circles-api.js";
import { useEffect } from "react";
import { PushNotifications } from "@capacitor/push-notifications";
import { LocalNotifications } from "@capacitor/local-notifications";
import tokenService from "../shared/services/token-service.js";
import AddSettlementDetail from "../secure/circle-details/components/add-settlement/add-settlement-detail.jsx";
import SettlePayee from "../secure/circle-details/components/add-settlement/payee.jsx";
import AddSettlement from "../secure/circle-details/components/add-settlement/page.jsx";
import { Capacitor } from "@capacitor/core";
import EssenceNavigationOverlay from "./essence/core/navigation-overlay/navigation-overlay.jsx";
import Terms from "../shared/components/terms/page.jsx";
import PrivacyPolicy from "../shared/components/privacy-policy/page.jsx";
import Theme from "../secure/circles/settings/theme/page.jsx";

CapacitorApp.addListener("backButton", ({ canGoBack }) => {
  if (canGoBack) {
    // navigate(-1);
    window.history.back();
  } else {
    CapacitorApp.exitApp();
  }
});

export default function AnimatedRoutes() {
  const location = useLocation();
  const { mutate: refreshToken } = useMutation(refreshTokenMutation());
  const navigate = useNavigate();
  const accessToken = tokenService.getAccessToken();

  useEffect(() => {
    registerPushNotificationListeners();
  }, []);

  const registerPushNotificationListeners = () => {
    const isPushNotificationsAvailable =
      Capacitor.isPluginAvailable("PushNotifications");

    if (isPushNotificationsAvailable) {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === "granted") {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener("registration", async (token) => {
        // console.log(token.value);
        tokenService.setFCMToken(token.value);
        refreshToken(token.value);
        // alert("Success on registration: " + JSON.stringify(token.value));
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener("registrationError", (error) => {
        // alert("Error on registration: " + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification) => {
          console.log(JSON.stringify(notification));
          var options = {
            notifications: [
              {
                ...notification,
                extra: notification.data,
                id: 1,
                schedule: {
                  allowWhileIdle: true,
                },
              },
            ],
          };
          LocalNotifications.schedule(options);
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification) => {
          console.log(`Notification: ${JSON.stringify(notification)}`);
          _handlePush(notification);
        }
      );

      LocalNotifications.addListener(
        "localNotificationActionPerformed",
        (notification) => {
          console.log(`Notification: ${JSON.stringify(notification)}`);
          _handlePush(notification);
        }
      );
    }
  };

  const _handlePush = (notification) => {
    // Add a delay of 300ms so as to await for the app to load if the app is started from scratch.
    setTimeout(() => {
      const path = notification.notification.data.path;
      navigate(path);
    }, 300);
  };

  const withAuth = (Component) => {
    return accessToken ? <Component /> : <Navigate to="/auth/login" replace />;
  };

  return (
    <EssenceNavigationOverlay id="overlay-date-picker">
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Navigate replace to="/auth/login" />} />

          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route
            element={
              accessToken ? (
                <Navigate to="/secure/circles" replace />
              ) : (
                <Panel title="circles" />
              )
            }
            path="/auth"
          >
            <Route path="login" element={<Login />} />
            <Route path="verify-otp" element={<VerifyOtp />} />
          </Route>
          <Route
            path="/secure/settings"
            element={<Panel title="Update your profile" />}
          >
            <Route path="profile" element={withAuth(Profile)} />
          </Route>
          <Route
            path="/secure/settings"
            element={<Panel title="Update your theme" />}
          >
            <Route path="theme" element={withAuth(Theme)} />
          </Route>
          <Route path="/secure/onboarding" element={<Panel />}>
            <Route path="circle-type" element={withAuth(CircleType)} />
            <Route path="circle-info" element={withAuth(CircleInfo)} />
            <Route
              path="circle-interests"
              element={withAuth(CircleInterests)}
            />
          </Route>
          <Route path="/secure/circles" element={withAuth(Circles)} />
          <Route
            path="/secure/circles/:circleId"
            element={withAuth(CircleDetail)}
          />
          <Route
            path="/secure/circles/:circleId/add-participant"
            element={withAuth(AddParticipants)}
          />
          <Route
            path="/secure/circles/:circleId/participants/add"
            element={withAuth(MobileAddParticipants)}
          />
          <Route
            path="/secure/circles/:circleId/participants"
            element={withAuth(CircleParticipants)}
          />
          <Route
            path="/secure/circles/:circleId/add-expense"
            element={withAuth(AddExpense)}
          >
            <Route path="expense-detail" element={withAuth(AddExpenseDetail)} />
            <Route path="participants" element={withAuth(Participants)} />
            <Route path="payee" element={withAuth(Payee)} />
          </Route>
          <Route
            path="/secure/expense/:expenseId"
            element={withAuth(ExpenseDetail)}
          />
          <Route
            path="/secure/circles/:circleId/add-settlement"
            element={withAuth(AddSettlement)}
          >
            <Route
              path="settlement-detail"
              element={withAuth(AddSettlementDetail)}
            />
            <Route path="payee" element={withAuth(SettlePayee)} />
          </Route>
          <Route
            path="/secure/circles/:circleId/add-itinerary"
            element={withAuth(AddItinerary)}
          >
            <Route
              path="itinerary-detail"
              element={withAuth(AddItineraryDetail)}
            />
            {/* <Route path="origin" element={withAuth(Origin)} />
          <Route path="destination" element={withAuth(Destination)} />
          <Route path="summary" element={withAuth(Summary)} /> */}
          </Route>
        </Routes>
      </AnimatePresence>
    </EssenceNavigationOverlay>
  );
}
