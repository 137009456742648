import { updateProfile, logout, deleteAccount } from "./api.jsx";
import { accountKeys } from "./keys.jsx";

export const setDefaultAccountMutation = (queryClient) => {
  // we need a default mutation function so that paused mutations can resume after a page reload
  queryClient.setMutationDefaults(accountKeys.update(), {
    mutationFn: async (account) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({
        queryKey: accountKeys.update(),
      });
      return await updateProfile(account);
    },
  });

  queryClient.setMutationDefaults(accountKeys.logout(), {
    mutationFn: async (account) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({
        queryKey: accountKeys.logout(),
      });
      return await logout(account);
    },
  });

  queryClient.setMutationDefaults(accountKeys.delete(), {
    mutationFn: async (account) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({
        queryKey: accountKeys.delete(),
      });
      return await deleteAccount(account);
    },
  });
};
