const ListItinerary = ({ itineraries,circleId }) =>{
    return (
        <div>
          {itineraries?.map((itinerary) => (
            <Link key={itinerary.itineraryId} to={`/secure/itinerary/${itinerary.itineraryId}`} state={{circleId}}>
              <EssenceAnimatedRow
                title={itinerary.title}
                subTitle={itinerary.startDateTime}
                actionText={itinerary.durationHour}
              />
            </Link>
          ))}
        </div>
    );
}

export default ListItinerary;