import EssenceAnimatedButton from "../../../../components/essence/button/animated-button";
import Header from "../../../../shared/components/header/page";
import Content from "../../../../shared/components/content/page";
import { useState, useEffect } from "react";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import tokenService from "../../../../shared/services/token-service";
import getMemberType from "../../member-type";
import { useCircleQueries } from "../../../../shared/tanstack/circle/queries";
import EssenceAnimatedRadio from "../../../../components/essence/radio/animated-radio";
import { useQuery } from "@tanstack/react-query";
import { jwtDecode } from "jwt-decode";
import { useExpenseQueries } from "../../../../shared/tanstack/expenses/queries";
import storageKeyService from "../../../../shared/services/storage-key-service";

const Payee = () => {
  const { circleId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const storageKey = searchParams.get("token");

  const { circleQuery } = useCircleQueries({ circleId });
  const { data: circle } = useQuery(circleQuery);

  const { expensesSummaryQuery } = useExpenseQueries({ circleId });
  const { data: expensesSummary } = useQuery(expensesSummaryQuery);

  const { participantsQuery } = useCircleQueries({ circleId });
  const { data: participants } = useQuery(participantsQuery);

  const settlmentFromState = location.state?.settlementDetail;
  if (settlmentFromState) {
    settlmentFromState.dateTime = new Date(settlmentFromState?.dateTime);
  }
  const existingSettlement = storageKeyService.getSettlementForKey(storageKey);

  if (existingSettlement) {
    existingSettlement.dateTime = new Date(existingSettlement.dateTime);
  }

  const [settlementDetail] = useState(
    existingSettlement
      ? existingSettlement
      : settlmentFromState
      ? settlmentFromState
      : { dateTime: new Date() }
  );

  const member = getMemberType(circle?.type)?.title ?? "";

  const accessToken = tokenService.getAccessToken();
  const loggedInUserId = jwtDecode(accessToken).userId;

  useEffect(() => {
    storageKeyService.removeSettlementsExceptKey(storageKey);
    if (location.state === null || location.state.settlementDetail === null) {
      navigate(-1);
    }
  }, []);

  const goToSettlementDetail = (e) => {
    e.preventDefault();
    storageKeyService.setSettlmentForKey(storageKey, settlementDetail);
    settlementDetail.isSettlement = true;
    navigate(`/secure/circles/${circleId}/add-settlement/settlement-detail`, {
      state: { settlementDetail },
    });
  };

  const _findParticipant = (userId) => {
    if (participants) {
      const filteredParticipant = participants.find((x) => x.userId == userId);
      return filteredParticipant;
    }
    return {};
  };

  return (
    <>
      <Header
        title={member}
        subtitle={`Select the ${member} to settle with.`}
      />
      <Content>
        {expensesSummary?.length > 0 && (
          <div className="mb-6 gap-20 items-center">
            {expensesSummary?.map((summary, i) => (
              <EssenceAnimatedRadio
                defaultChecked={i === 0}
                key={summary.creditorId}
                text={
                  summary.creditorId !== loggedInUserId
                    ? `Pay ${
                        _findParticipant(
                          summary.creditorId !== loggedInUserId
                            ? summary.creditorId
                            : summary.debtorId
                        )?.name
                      } ${summary.currency}${summary.amount}`
                    : `Collect from ${
                        _findParticipant(
                          summary.creditorId !== loggedInUserId
                            ? summary.creditorId
                            : summary.debtorId
                        )?.name
                      } ${summary.currency}${summary.amount}`
                }
                name="creditors"
                value={summary.creditorId}
              />
            ))}

            <div className="mt-6"></div>
            <EssenceAnimatedButton name="next" onClick={goToSettlementDetail} />
          </div>
        )}
      </Content>
    </>
  );
};

export default Payee;
