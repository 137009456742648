const _getStorageKey = (prefix, value) => {
  return `${prefix}-${value}`;
};

const _getValue = (key) => {
  const valueStr = localStorage.getItem(key);
  if (valueStr) {
    try {
      return JSON.parse(valueStr);
    } catch {}
  }
  return undefined;
};

const _setValue = (key, value) => {
  try {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.removeItem(key);
    }
  } catch {}
};

const _removeValues = (prefix, storageKey) => {
  const keys = Object.keys(localStorage);
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];

    if (key.indexOf(prefix) === 0 && key !== storageKey) {
      localStorage.removeItem(key);
    }
  }
};

const getSettlementForKey = (key) => {
  const fullKey = _getStorageKey("settlement-storage-key", key);
  return _getValue(fullKey);
};

const setSettlmentForKey = (key, value) => {
  const fullKey = _getStorageKey("settlement-storage-key", key);
  _setValue(fullKey, value);
};

const removeSettlementsExceptKey = (key) => {
  const fullKey = _getStorageKey("settlement-storage-key", key);
  _removeValues("settlement-storage-key", fullKey);
};

const getExpenseForKey = (key) => {
  const fullKey = _getStorageKey("expense-storage-key", key);
  return _getValue(fullKey);
};

const setExpenseForKey = (key, value) => {
  const fullKey = _getStorageKey("expense-storage-key", key);
  _setValue(fullKey, value);
};

const removeExpensesExceptKey = (key) => {
  const fullKey = _getStorageKey("expense-storage-key", key);
  _removeValues("expense-storage-key", fullKey);
};

export default {
  getSettlementForKey,
  setSettlmentForKey,
  removeSettlementsExceptKey,
  getExpenseForKey,
  setExpenseForKey,
  removeExpensesExceptKey
};
