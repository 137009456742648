import { addItinerary, updateItinerary, deleteItinerary } from "./api.jsx";
import { itineraryKeys } from "./keys.jsx";

export const setDefaultItineraryMutation = (queryClient) => {
  // we need a default mutation function so that paused mutations can resume after a page reload
  queryClient.setMutationDefaults(itineraryKeys.add(), {
    mutationFn: async (itinerary) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({
        queryKey: itineraryKeys.add(),
      });
      return await addItinerary(itinerary);
    },
  });

  // we need a default mutation function so that paused mutations can resume after a page reload
  queryClient.setMutationDefaults(itineraryKeys.delete(), {
    mutationFn: async (itinerary) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({
        queryKey: itineraryKeys.delete(),
      });
      const itineraryToUpdate = _getCachedItinerary(itinerary);
      return await deleteItinerary(itineraryToUpdate);
    },
  });

  // we need a default mutation function so that paused mutations can resume after a page reload
  queryClient.setMutationDefaults(itineraryKeys.update(), {
    mutationFn: async (itinerary) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({
        queryKey: itineraryKeys.update(),
      });
      const itineraryToUpdate = _getCachedItinerary(itinerary);
      return await updateItinerary(itineraryToUpdate);
    },
  });

  const _getCachedItinerary = (itinerary) => {
    const itineraries = queryClient.getQueryData(
      itineraryKeys.list(itinerary.circleId)
    );
    const updatedItinerary = itineraries?.filter(
      (x) => x.clientItineraryId === itinerary.itineraryId
    );
    return updatedItinerary?.length > 0 ? updatedItinerary[0] : itinerary;
  };
};
