import api from "../../services/api";
import { itineraryKeys } from "./keys";

export const useItineraryQueries = ({ circleId, itineraryId }) => {
  const itinerariesQuery = {
    queryKey: itineraryKeys.list(circleId),
    queryFn: () =>
      api.get(`/api/itinerary?circleId=${circleId}`).then((res) => {
        const serverItems = res.data;
        const clientItems = queryClient.getQueryData(
          itineraryKeys.list(circleId)
        );
        const items = getItems(serverItems, clientItems, "itineraryId");
        return items;
      }),
    refetchOnReconnect: false,
    select: (itineraries) =>
      itineraries.filter(
        (itinerary) => itinerary.syncStatus !== "PENDING_DELETE"
      ),
  };

  const itineraryQuery = {
    refetchOnReconnect: false,
    queryKey: itineraryKeys.detail(itineraryId),
    queryFn: () =>
      api.get(`/api/itinerary/${itineraryId}`).then((res) => res.data),
  };

  return {
    itinerariesQuery,
    itineraryQuery,
  };
};
