import axios from "axios";
import tokenService from "./token-service.js";

const instance = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}`,
  headers: {
    "Content-Type": "application/json",
    apiKey: import.meta.env.VITE_API_KEY,
  },
});

instance.interceptors.request.use(
  async (config) => {
    const token = tokenService.getAccessToken();
    config.headers["ngrok-skip-browser-warning"] = "true";
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          // TODO: Refresh the token
          // tokenService.setToken(token);

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
