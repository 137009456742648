import styles from "./picker-option.module.css";

export default function EssencePickerOption({
  option,
  isActive,
  isScrolling,
  isHovering,
  onClick,
}) {
  return (
    <div
      onClick={() => {
        onClick(option);
      }}
      className={`${styles["picker-option"]} ${isActive ? styles.active : ""} ${
        isScrolling ? styles.scrolling : ""
      } ${isHovering ? styles.hovering : ""}`}
    >
      <div className={styles["picker-option-title"]}>{option?.title}</div>
      <div className={styles["picker-option-sub-title"]}>
        {option?.subtitle}
      </div>
    </div>
  );
}
