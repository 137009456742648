import { useQueryClient } from "@tanstack/react-query";
import { v4 as uuid } from "uuid";
import { expensesKeys } from "./keys";

export const useExpenseMutations = ({ circleId }) => {
  const queryClient = useQueryClient();

  const addExpenseMutation = {
    mutationKey: expensesKeys.add(),
    onMutate: async (newExpense) => {
      await queryClient.cancelQueries({
        queryKey: expensesKeys.list(circleId),
      });
      const previousExpenses = queryClient.getQueryData(
        expensesKeys.list(circleId)
      );
      const clientExpenseId = uuid();

      const updatedExpenses = [
        {
          ...newExpense,
          expenseId: clientExpenseId,
          clientExpenseId,
          syncStatus: "PENDING_CREATE",
          createdAt: new Date(),
          updatedAt: new Date(),
          // dateTime: newExpense.dateTime.toISOString()
        },
        ...previousExpenses,
      ];
      updatedExpenses.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));

      // Optimistically update to the new value
      queryClient.setQueryData(expensesKeys.list(circleId), updatedExpenses);

      // Return a context object with the snapshotted value
      return { previousExpenses, clientExpenseId };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newExpense, context) => {
      queryClient.setQueryData(
        expensesKeys.list(circleId),
        context.previousExpenses
      );
    },
    onSuccess: (data, variables, context) => {
      // Optimistically update to the new value
      queryClient.setQueryData(
        expensesKeys.list(variables.circleId),
        (expenses) => {
          const updatedExpenses = expenses.map((expense) => {
            return expense.clientExpenseId === context.clientExpenseId
              ? {
                  ...expense,
                  expenseId: data.expenseId,
                }
              : expense;
          });
          return updatedExpenses;
        }
      );
    },
  };

  const deleteExpenseMutation = {
    mutationKey: expensesKeys.delete(),
    onMutate: async (newExpense) => {
      await queryClient.cancelQueries({
        queryKey: expensesKeys.list(circleId),
      });

      // Snapshot the previous value
      const previousExpenses = queryClient.getQueryData(
        expensesKeys.list(circleId)
      );

      // Optimistically update to the new value
      queryClient.setQueryData(expensesKeys.list(circleId), (expenses) =>
        expenses.map((expense) => {
          if (expense.expenseId === newExpense.expenseId) {
            return {
              ...expense,
              deletedAt: new Date(),
              syncStatus: "PENDING_DELETE"
            };
          } else {
            return expense;
          }
        })
      );

      // Return a context object with the snapshotted value
      return { previousExpenses };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newExpense, context) => {
      queryClient.setQueryData(
        expensesKeys.list(circleId),
        context.previousExpenses
      );
    }
  };

  const updateExpenseMutation = {
    mutationKey: expensesKeys.update(),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: expensesKeys.list(circleId),
      });
      // Snapshot the previous value
      const previousExpenses = queryClient.getQueryData(
        expensesKeys.list(circleId)
      );

      const filteredExpenses = previousExpenses.filter(
        (x) => x.expenseId !== variables.expenseId
      );

      const updatedExpenses = [
        ...filteredExpenses,
        {
          ...variables,
          // dateTime: variables.dateTime.toISOString(),
          updatedAt: new Date()
        },
      ];
      updatedExpenses.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));

      // Optimistically update to the new value
      queryClient.setQueryData(expensesKeys.list(circleId), updatedExpenses);


      // Return a context object with the snapshotted value
      return { previousExpenses };
    },
  };

  return {
    addExpenseMutation,
    deleteExpenseMutation,
    updateExpenseMutation,
  };
};
