"use client";
import React, { useEffect, useState } from "react";
import EssenceButton from "../../../../components/essence/button/button";
import Header from "../../../../shared/components/header/page";
import Content from "../../../../shared/components/content/page";
import { useLocation, useNavigate } from "react-router-dom";
import { useAccountQueries } from "../../../../shared/tanstack/account/queries";
import useLoaderDisplay from "../../../../shared/hooks/loader-display";
import EssenceAlert from "../../../../components/essence/alert/alert";
import { useAccountMutations } from "../../../../shared/tanstack/account/mutations";
import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { onlineManager } from "@tanstack/react-query";
import { useCircleQueries } from "../../../../shared/tanstack/circle/queries";
import Clickable from "../../../../components/essence/core/clickable";
import Animated from "../../../../components/essence/animated/animated";
import styles from "./page.module.css";
import tokenService from "../../../../shared/services/token-service";
import { useQueryClient } from "@tanstack/react-query";
import coachMarkManager from "../../../services/coach-mark";

export default function Profile() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { accountQuery } = useAccountQueries();
  const { updateProfileMutation, deleteAccountMutation } =
    useAccountMutations();
  const [skipCirclesQuery, setSkipCirclesQuery] = useState(true);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const {
    mutate: updateProfile,
    isPaused,
    status,
    isSuccess,
    isError,
  } = useMutation(updateProfileMutation);

  const {
    mutate: deleteAccount,
    isSuccess: deleteAccountSuccess,
    isError: deleteAccountError,
    isPaused: isDeleteAccountPaused,
    status: deleteAccountStatus,
    reset: deleteAccountReset,
  } = useMutation(deleteAccountMutation);

  useLoaderDisplay({
    key: "updateProfile",
    isLoading: status === "pending" && !isPaused,
  });

  useLoaderDisplay({
    key: "deleteAccount",
    isLoading: deleteAccountStatus === "pending" && !isDeleteAccountPaused,
  });

  const { data: account } = useQuery(accountQuery);
  const [userName, setUserName] = useState(account?.name);
  const [alert, setAlert] = useState({ show: false });
  const { circlesQuery } = useCircleQueries({ skipCirclesQuery });
  const { data: circles, isLoading: isCirclesLoading } = useQuery(circlesQuery);
  useLoaderDisplay({ key: "getCircles", isLoading: isCirclesLoading });

  const { state } = useLocation();

  useEffect(() => {
    if (!onlineManager.isOnline() && account === undefined) {
      setAlert({
        show: true,
        message:
          "Please connect to the internet to get details of your account.",
      });
    }
  }, []);

  useEffect(() => {
    setUserName(account?.name);
  }, [account]);

  useEffect(() => {
    if (deleteAccountSuccess) {
      tokenService.removeToken();
      queryClient.removeQueries();
      coachMarkManager.reset();
      navigate("/auth/login", { replace: true });
    }
  }, [deleteAccountSuccess]);

  useEffect(() => {
    if (deleteAccountError) {
      setAlert({
        show: true,
        message:
          "There was some error deleting your account. Please try again later.",
      });
      deleteAccountReset();
    }
  }, [deleteAccountError]);

  useEffect(() => {
    if (isSuccess) {
      if (state?.context === "UPDATE_PROFILE") {
        navigate(-1);
      } else {
        localStorage.removeItem("isProfileCreationPending");
        setSkipCirclesQuery(false);
      }
    } else if (isError) {
      setAlert({
        show: true,
        message:
          "There was some error updating the profile. Please try again later.",
      });
      // Show error updating profile
    }
  }, [isSuccess]);

  useEffect(() => {
    if (state?.context !== "UPDATE_PROFILE") {
      if (circles) {
        navigate(
          circles.length > 0
            ? "/secure/circles"
            : "/secure/onboarding/circle-info",
          {
            replace: true,
          }
        );
      }
    }
  }, [circles]);

  const hideAlert = () => {
    setAlert({
      show: false,
    });
    if (!onlineManager.isOnline()) {
      navigate(-1);
    }
  };
  const submitForm = (e) => {
    // We don't want the page to refresh
    e.preventDefault();
    updateProfile({
      name: userName.trim(),
      profileImage: "sample",
    });
  };

  const onDeleteAccount = () => {
    setShowDeleteAlert(false);
    deleteAccount();
  };

  return (
    <>
      {alert.show && (
        <EssenceAlert
          onOk={hideAlert}
          onDismiss={hideAlert}
          okButton="okay"
          message={alert.message}
        ></EssenceAlert>
      )}

      {showDeleteAlert === true && (
        <EssenceAlert
          onOk={onDeleteAccount}
          onDismiss={() => setShowDeleteAlert(false)}
          okButton="delete"
          dismissButton="don't delete"
          message={
            "Deleting account will delete all your circles along with all other data. Are you sure?"
          }
        ></EssenceAlert>
      )}

      <Header title="Profile" subtitle="visible to others in your circles" />
      <Content>
        <form onSubmit={submitForm}>
          <p className="headline-5">Phone Number</p>
          <p className="headline-4">
            {account?.phoneNumber
              ? `+${account.countryCode} ${account.phoneNumber}`
              : `+${location.state.countryCode} ${location.state.phoneNumber}`}
          </p>
          <p className="headline-5 mt-6">Name</p>
          <input
            essence-input="true"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <EssenceButton
            type="submit"
            name={state?.context === "UPDATE_PROFILE" ? "save" : "next"}
          />
        </form>
        {state?.context === "UPDATE_PROFILE" && (
          <div className={styles["delete-btn"]}>
            <Clickable key="exit">
              <Animated>
                <div
                  className="headline-4 lowercase"
                  style={{ color: "red" }}
                  onClick={() => setShowDeleteAlert(true)}
                >
                  delete account
                </div>
              </Animated>
            </Clickable>
          </div>
        )}
      </Content>
    </>
  );
}
