import api from "../../services/api";
import { getItems } from "../query-response-manager";
import { expensesKeys } from "./keys";
import { useQueryClient } from "@tanstack/react-query";

export const useExpenseQueries = ({ circleId, expenseId }) => {
  const queryClient = useQueryClient();

  const expensesQuery = {
    refetchOnReconnect: false,
    queryKey: expensesKeys.list(circleId),
    queryFn: () =>
      api.get(`/api/expense?circleId=${circleId}`).then((res) => {
        const serverItems = res.data;
        const clientItems = queryClient.getQueryData(
          expensesKeys.list(circleId)
        );
        const items = getItems(serverItems, clientItems, "expenseId");
        items.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
        return items;
      }),
    select: (expenses) =>
      expenses.filter((expense) => expense.syncStatus !== "PENDING_DELETE"),
  };

  const expensesSummaryQuery = {
    refetchOnReconnect: false,
    queryKey: expensesKeys.summary(circleId),
    queryFn: () =>
      api
        .get(`/api/expense/summary?circleId=${circleId}`)
        .then((res) => res.data),
  };

  const expenseQuery = {
    refetchOnReconnect: false,
    queryKey: expensesKeys.detail(expenseId),
    queryFn: () => api.get(`/api/expense/${expenseId}`).then((res) => res.data),
  };

  return {
    expensesQuery,
    expensesSummaryQuery,
    expenseQuery,
  };
};
