import Clickable from "../core/clickable";
import styles from "./button.module.css";

export default function EssenceButton(props) {
  return (
    <Clickable disabled={props.disabled}>
      <button
        className={`${props.className} ${styles["essence-button"]}`}
        {...props}
      >
        {props.name}
      </button>
    </Clickable>
  );
}
