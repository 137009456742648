import { useState, useEffect } from "react";
import Page from "../../../../components/essence/page/page.jsx";
import Animated from "../../../../components/essence/animated/animated.jsx";
import AnimatedParent from "../../../../components/essence/animated/animated-parent.jsx";
import getMemberType from "../../member-type.jsx";
import { useNavigate, useParams } from "react-router-dom";
import EssenceCheckbox from "../../../../components/essence/checkbox/checkbox.jsx";
import { useCircleQueries } from "../../../../shared/tanstack/circle/queries.jsx";
import { useCircleMutations } from "../../../../shared/tanstack/circle/mutations.jsx";
import { useQuery, useMutation } from "@tanstack/react-query";
import EssenceAlert from "../../../../components/essence/alert/alert.jsx";
import styles from "./page.module.css";
import tokenService from "../../../../shared/services/token-service.js";
import { jwtDecode } from "jwt-decode";
import Clickable from "../../../../components/essence/core/clickable.jsx";
import contactsManager from "../../../services/contacts-manager.js";

const CircleParticipants = () => {
  const navigate = useNavigate();
  const [localParticipants, setLocalParticipants] = useState([]);
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [disabledParticipants, setDisabledParticipants] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { circleId } = useParams();
  const { circleQuery, participantsQuery } = useCircleQueries({ circleId });
  const { data: circle } = useQuery(circleQuery);
  const member = getMemberType(circle?.type)?.pluralTitle ?? "";
  const { data: participants } = useQuery(participantsQuery);
  const { deleteParticipantsMutation } = useCircleMutations({ circleId });
  const { deleteCircleMutation } = useCircleMutations({});
  const { mutate: exitCircle } = useMutation(deleteCircleMutation);

  const { mutate: removeParticipant } = useMutation(deleteParticipantsMutation);
  const [alert, setAlert] = useState({ show: false });
  const [warningAlert, setWarningAlert] = useState({
    show: false,
    something: false,
  });

  useEffect(() => {
    const fetchedParticipants =
      participants?.map((participant) =>
        contactsManager.getParticipant(participant)
      ) ?? [];
    const lastFourParticipants =
      fetchedParticipants
        .filter((x) => x.lastFourNumber?.length > 0)
        .sort((x, y) => x.lastFourNumber > y.lastFourNumber) ?? [];

    setDisabledParticipants(lastFourParticipants);

    const localParticipants =
      fetchedParticipants
        .filter((x) => x.phoneNumber?.length > 0 ?? true)
        .sort((x, y) => x.name > y.name) ?? [];

    setLocalParticipants(localParticipants);
    setFilteredParticipants(localParticipants);
  }, [participants]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleToggleCompletion = (participant) => {
    const accessToken = tokenService.getAccessToken();
    const loggedInUserId = jwtDecode(accessToken).userId;
    if (participant.userId === loggedInUserId) {
      setWarningAlert({
        show: true,
        message: "Are you sure you want to exit the circle?",
        okButton: "exit",
        context: {
          participant,
          type: "HIDE_PARTICIPANT",
        },
      });
    } else {
      setWarningAlert({
        show: true,
        message: `Are you sure you want to remove ${participant.name} from ${circle.name}?`,
        okButton: "remove",
        context: {
          participant,
          type: "REMOVE_PARTICIPANT",
        },
      });
    }
  };

  const hideAlert = () => {
    setAlert({
      show: false,
    });
    setWarningAlert({
      show: false,
    });
  };

  useEffect(() => {
    if (searchTerm.length === 0) {
      if (localParticipants.length > 0) {
        setFilteredParticipants(
          localParticipants?.map((participant) =>
            contactsManager.getParticipant(participant)
          )
        );
      }
    } else {
      const filtered = localParticipants
        .map((participant) => contactsManager.getParticipant(participant))
        .filter(
          (participant) =>
            participant.name
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            participant.phoneNumber?.includes(searchTerm)
        );
      setFilteredParticipants(filtered);
    }
  }, [searchTerm]);

  const getName = (participant) => {
    return participant.name?.length === 0 ? "-" : participant.name;
  };

  const getPhoneNumber = (participant) => {
    const phoneNumber =
      participant.phoneNumber && participant.phoneNumber.length > 0
        ? participant.phoneNumber
        : `xxx.xxx.${participant.lastFourNumber}`;
    return `+${participant.countryCode} ${phoneNumber}`;
  };

  const warningAlertAction = () => {
    if (warningAlert.context.type === "REMOVE_PARTICIPANT") {
      _removeParticipant();
    } else if (warningAlert.context.type === "EXIT_CIRCLE") {
      _exitCircle();
    }
  };
  const _removeParticipant = () => {
    const participant = warningAlert.context.participant;
    removeParticipant({
      circleId,
      contacts: [warningAlert.context.participant],
    });
    const newParticipants = filteredParticipants.filter(
      (x) => x.userId !== participant.userId
    );
    setFilteredParticipants(newParticipants);
    hideAlert();
  };
  const _exitCircle = () => {
    exitCircle({ circleId });
    setWarningAlert({ show: false });
    navigate(-2);
  };
  const goToAddParticipants = () => {
    navigate(`/secure/circles/${circleId}/participants/add`);
  };

  return (
    <AnimatedParent>
      {alert.show && (
        <EssenceAlert
          onOk={hideAlert}
          okButton="okay"
          message={alert.message}
        ></EssenceAlert>
      )}

      {warningAlert.show && (
        <EssenceAlert
          onOk={warningAlertAction}
          okButton={warningAlert.okButton}
          onDismiss={hideAlert}
          dismissButton={"cancel"}
          message={warningAlert.message}
        ></EssenceAlert>
      )}
      <Page title={member}>
        <div>
          {/* <Animated>
            <input
              essence-input="true"
              type="text"
              placeholder={"search " + member}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Animated> */}
          <Clickable key="add-participants">
            {/* <Animated> */}
            <div
              className="headline-4 lowercase my-6"
              onClick={goToAddParticipants}
            >
              + add {member}
            </div>
            {/* </Animated> */}
          </Clickable>

          {filteredParticipants?.map((participant) => {
            return (
              <EssenceCheckbox
                title={getName(participant)}
                subTitle={getPhoneNumber(participant)}
                checked={true}
                onChange={() => handleToggleCompletion(participant)}
              />
            );
          })}

          {disabledParticipants?.length > 0 && (
            <div className="mt-10 body-1 opacity-50">
              Below {member} can only be removed by the person who added them.
            </div>
          )}
          {disabledParticipants?.map((participant) => {
            return (
              <EssenceCheckbox
                title={getName(participant)}
                subTitle={getPhoneNumber(participant)}
                disabled={true}
                checked={true}
                onChange={() => handleToggleCompletion(participant)}
              />
            );
          })}

          {/* <div className={`${styles["add-participants"]} mt-10`}>
              <EssenceButton
                name="save participants"
                onClick={saveParticipants}
              />
            </div> */}
        </div>
      </Page>
    </AnimatedParent>
  );
};

export default CircleParticipants;
