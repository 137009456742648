import { useEffect } from "react";
import { onlineManager } from "@tanstack/react-query";

var pendingStatuses = {};
export default function useLoaderDisplay({ key, isLoading }) {
  useEffect(() => {
    if (onlineManager.isOnline()) {
      if (isLoading) {
        if (
          document.getElementById("loader") &&
          Object.keys(pendingStatuses).length === 0
        ) {
          document.getElementById("loader").style.display = "block";
        }
        pendingStatuses[key] = isLoading;
      } else {
        delete pendingStatuses[key];
        if (
          document.getElementById("loader") &&
          Object.keys(pendingStatuses).length === 0
        ) {
          document.getElementById("loader").style.display = "none";
        }
      }
    } else {
      document.getElementById("loader").style.display = "none";
    }
  }, [isLoading]);
}
