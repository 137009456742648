import { useQueryClient } from "@tanstack/react-query";
import api from "../../services/api";
import { circlesKeys } from "./keys.jsx";
import { getItems } from "../query-response-manager.jsx";
import contactsManager from "../../../secure/services/contacts-manager.js";

export const useCircleQueries = ({ skipCirclesQuery, circleId }) => {
  const queryClient = useQueryClient();

  const circlesQuery = {
    refetchOnReconnect: false,
    queryKey: circlesKeys.list(),
    queryFn: () =>
      api.get(`/api/circle`).then((res) => {
        const serverItems = res.data;
        const clientItems = queryClient.getQueryData(circlesKeys.list());
        const items = getItems(serverItems, clientItems, "circleId");
        return items;
      }),
    enabled: !skipCirclesQuery,
    select: (circles) =>
      circles.filter((circle) => circle.syncStatus !== "PENDING_DELETE"),
  };

  const circleQuery = {
    refetchOnReconnect: false,
    queryKey: circlesKeys.detail(circleId),
    queryFn: () => api.get(`/api/circle/${circleId}`).then((res) => res.data),
  };

  const participantsQuery = {
    refetchOnReconnect: false,
    queryKey: circlesKeys.participants(circleId),

    queryFn: () =>
      api.get(`/api/circle/${circleId}/participant`).then((res) => {
        contactsManager.addParticipants(circleId, res.data);

        const serverItems = res.data;
        const clientItems = queryClient.getQueryData(
          circlesKeys.participants(circleId)
        );
        const mappedServerItems = serverItems.map((serverItem) => {
          const clientItem = clientItems?.find(
            (clientItem) => serverItem.userId === clientItem.userId
          );
          if (clientItem) {
            const mappedItem = {
              ...serverItem,
              ...clientItem,
              updatedAt: new Date(),
            };
            delete mappedItem.syncStatus;
            return mappedItem;
          } else {
            return serverItem;
          }
        });

        const items = getItems(mappedServerItems, clientItems, "userId");
        return items;
      }),
      select: (participants) =>
      participants.filter((participant) => participant.syncStatus !== "PENDING_DELETE"),
  };

  return {
    circlesQuery,
    circleQuery,
    participantsQuery,
  };
};
