import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import themeService from "./shared/services/theme-service.js";

const queryClient = new QueryClient();

themeService.loadTheme();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <div>
        <App />
      </div>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>
);
