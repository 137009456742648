import styles from './logo.module.css'

export default function Logo({ size }) {

  const style = { "--circle-size": size };


  return (
    <div style={style} className={styles.container}>
      <div className={`${styles.circle} ${styles.first}`} />
      <div className={styles.toprow}>
        <div className={styles.circle} />
        <div className={styles.circle}/>
      </div>
    </div>
  )
}
