"use client";

import Animated from "../animated/animated";
import EssenceCheckbox from "./checkbox";

export default function EssenceAnimatedCheckbox(props) {
  return (
    <Animated>
      <EssenceCheckbox {...props} />
    </Animated>
  );
}
