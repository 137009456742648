import Hub from "../../components/essence/hub/hub";
import Clickable from "../../components/essence/core/clickable";
import Animated from "../../components/essence/animated/animated";
import { useNavigate } from "react-router-dom";
import CircleList from "./components/circle-list";
import AnimatedParent from "../../components/essence/animated/animated-parent";
import tokenService from "../../shared/services/token-service";
import { useQueryClient } from "@tanstack/react-query";
import { useAccountMutations } from "../../shared/tanstack/account/mutations";
import { useMutation } from "@tanstack/react-query";
import swipeLeftAnimation from "./../../../scroll-left.json";
import { useState, useEffect } from "react";
import styles from "./page.module.css";
import coachMarkManager from "../services/coach-mark";
import { BranchDeepLinks } from "capacitor-branch-deep-links";
import { jwtDecode } from "jwt-decode";
import { Share } from "@capacitor/share";
import { Capacitor } from "@capacitor/core";

export default function Circles() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [displayAnimation, setDisplayAnimation] = useState(
    coachMarkManager.shouldShowCoachMark("landing")
  );

  const hubItems = [
    {
      title: "My Circles",
    },
    {
      title: "Settings",
    },
  ];

  const { logoutMutation } = useAccountMutations();
  const { mutate: logout } = useMutation(logoutMutation);

  const handleAddCircle = () => {
    // navigate("/secure/onboarding/circle-type", { replace: true });
    navigate("/secure/onboarding/circle-info");
  };

  const goToProfile = () => {
    navigate("/secure/settings/profile", {
      state: { context: "UPDATE_PROFILE" },
    });
  };

  const goToTerms = () => {
    navigate("/terms", {
      state: { context: "UPDATE_PROFILE" },
    });
  };

  const goToPrivacy = () => {
    navigate("/privacy", {
      state: { context: "UPDATE_PROFILE" },
    });
  };
  const onLogout = () => {
    tokenService.removeToken();
    queryClient.removeQueries();
    coachMarkManager.reset();
    logout();
    navigate("/auth/login", { replace: true });
  };

  const goToTheme = () => {
    navigate("/secure/settings/theme");
  };

  const onSwipe = () => {
    coachMarkManager.hideCoachMark("landing");
    setDisplayAnimation(false);
  };

  useEffect(() => () => coachMarkManager.removeGestureListener(onSwipe), []);

  useEffect(() => {
    if (displayAnimation) {
      coachMarkManager.addGestureListener({
        listener: onSwipe,
        animation: {
          elementSelector: "#left-swipe",
          lottie: swipeLeftAnimation,
        },
      });
    }
  }, [displayAnimation]);

  const shareApp = async () => {
    const shareText =
      "Discover a world of possibilities – join us on Circles! Click the link to embark on your journey";
    var url = "https://circlesco.app.link/share";
    if (Capacitor.getPlatform() !== "web") {
      // optional fields
      const accessToken = tokenService.getAccessToken();
      const loggedInUserId = jwtDecode(accessToken).userId;
      var analytics = {
        channel: "native",
        feature: "referral",
        campaign: "referral for pr",
        stage: "new user",
      };
      const properties = {
        referrerId: loggedInUserId,
      };
      // share sheet
      const res = await BranchDeepLinks.generateShortUrl({
        analytics,
        properties,
        shareText,
      });
      url = res.url;
    }
    await Share.share({ url: url, text: shareText });
  };

  return (
    <AnimatedParent>
      <Hub title={"Circles"} hubItems={hubItems}>
        <div className={styles.container}>
          <div>
            {/* <EssenceAnimatedButton
              onClick={handleAddCircle}
              name="Add Circle"
            /> */}
            <div className="mt-10" />
            <Clickable key="add-expenses">
              <Animated>
                <div className="headline-4 lowercase" onClick={handleAddCircle}>
                  + add circle
                </div>
              </Animated>
            </Clickable>
            <div className="mt-10" />
            <CircleList />
          </div>
          {displayAnimation && (
            <div id="left-swipe" className={styles["coach-mark"]}></div>
          )}
        </div>

        <div className="flex flex-col">
          <Clickable key="profile">
            <Animated>
              <div className="headline-4 lowercase" onClick={goToProfile}>
                profile
              </div>
            </Animated>
          </Clickable>
          <div className="mt-4" />
          <Clickable key="share">
            <Animated>
              <div className="headline-4 lowercase" onClick={shareApp}>
                share the app
              </div>
            </Animated>
          </Clickable>
          <div className="mt-4" />
          <Clickable key="theme">
            <Animated>
              <div className="headline-4 lowercase" onClick={goToTheme}>
                Theme
              </div>
            </Animated>
          </Clickable>
          <div className="mt-4" />
          <Clickable key="privacy_policy">
            <Animated>
              <div className="headline-4 lowercase" onClick={goToPrivacy}>
                Privacy Policy
              </div>
            </Animated>
          </Clickable>
          <div className="mt-4" />
          <Clickable key="tos">
            <Animated>
              <div className="headline-4 lowercase" onClick={goToTerms}>
                Terms & Condition
              </div>
            </Animated>
          </Clickable>
          <div className="mt-4" />
          <Clickable key="logout">
            <Animated>
              <div
                className="headline-4 lowercase"
                onClick={onLogout}
                style={{ color: "red" }}
              >
                logout
              </div>
            </Animated>
          </Clickable>
        </div>
      </Hub>
    </AnimatedParent>
  );
}
