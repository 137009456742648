import { useNavigate } from "react-router-dom";
import EssenceAnimatedButton from "../../../../components/essence/button/animated-button.jsx";
import { useQuery } from "@tanstack/react-query";
import ListItinerary from "./list-itinerary.jsx";
import { useItineraryQueries } from '../../../../shared/tanstack/itinerary/queries.jsx';

export default function Itinerary(props) {
  const navigate = useNavigate();
  const { itineraryQuery } = useItineraryQueries({
    circleId: props.circleId,
  });
  const { data: itinerary } = useQuery(itineraryQuery);

  const goToAddItinerary = () => {
    localStorage.removeItem(`itinerary-detail`);
    navigate(`/secure/circles/${props.circleId}/add-itinerary/itinerary-detail`);
  };

  return (
    <>
      <EssenceAnimatedButton name="Add Itinerary" onClick={goToAddItinerary} />
      <ListItinerary itinerary={itinerary} circleId={props.circleId} />
    </>
  );
}
