import Animated from "../animated/animated";
import styles from "./page.module.css";

export default function Page({ title, children, onTitleClick }) {
  return (
    <div className={`${styles.component}`}>
      <div className={`${styles.title}`} onClick={onTitleClick}>
        <Animated>{title}</Animated>
      </div>
      <div className={`${styles.template}`}>{children}</div>
    </div>
  );
}
