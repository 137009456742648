import { Reorder } from "framer-motion";
import TodoRow from "./todo-item";
import { useTodoMutations } from "../../../../shared/tanstack/todos/mutations";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

export default function ListTodo({ todos, setTodos, onToggle, onRemove }) {
  const { circleId } = useParams();
  const { reorderToDoMutation } = useTodoMutations({ circleId });
  const { mutate: reorderToDo } = useMutation(reorderToDoMutation);

  const onDragEnd = () => {
    const reorderedTodos = todos.map((todo, i) => {
      return { toDoId: todo.toDoId, order: i };
    });
    if (reorderedTodos.length > 0) {
      reorderToDo(reorderedTodos);
    }
  };

  return (
    <Reorder.Group axis="y" values={todos} onReorder={setTodos}>
      {todos?.map((todo) => (
        <TodoRow
          todo={todo}
          key={todo.toDoId}
          onToggle={onToggle}
          onRemove={onRemove}
          onDragEnd={onDragEnd}
        ></TodoRow>
      ))}
    </Reorder.Group>
  );
}
