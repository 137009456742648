"use client";
import Clickable from "../core/clickable";
import styles from "./checkbox.module.css";

export default function EssenceCheckbox({
  title,
  subTitle,
  onActionClick,
  actionIcon,
  checkBoxColor,
  ...props
}) {
  const onAction = ($event) => {
    $event.preventDefault();
    onActionClick($event);
  };
  return (
    <Clickable disabled={props.disabled}>
      <label
        className={`${props.subTitle ? styles["checkbox-subtitle"] : ""} ${
          styles["checkbox"]
        } ${props.className} ${
          props.disabled ? styles["checkbox-disabled"] : ""
        }`}
      >
        <input type="checkbox" {...props} />
        <div
          className={`${subTitle ? styles["checkmark-subtitle"] : ""} ${
            styles["checkmark"]
          }`}
          style={{ "--theme-color": checkBoxColor }}
        ></div>

        <div className={styles.content}>
          <div className="subtitle-1">{title}</div>
          {subTitle && <div className="caption">({subTitle})</div>}
        </div>
        <div
          className={`${styles["action-icon"]} ${actionIcon} px-3`}
          onClick={onAction}
        ></div>
      </label>
    </Clickable>
  );
}
