"use client";
import styles from "./picker-overlay.module.css";
export default function EssencePickerOverlay({ children, onAccept, onReject }) {
  return (
    <div className={styles["picker-overlay"]}>
      {children}
      <div className={styles["action-bar"]}>
        <div
          onClick={onAccept}
          className={`fa-regular fa-circle-check ${styles["action-icon"]}`}
        ></div>
        <div
          onClick={onReject}
          className={`fa-regular fa-circle-xmark ${styles["action-icon"]}`}
        ></div>
      </div>
    </div>
  );
}
