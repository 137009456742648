"use client";
import {
  transformRotation,
  transformScale,
} from "../service/transform-service";
import styles from "./clickable.module.css";

export default function Clickable({ children, disabled = false }) {
  const mousedown = ($event) => {
    if (disabled) {
      $event.preventDefault();
      return;
    }
    transformScale($event.currentTarget.childNodes[0], 0.98);
  };

  const mouseup = ($event) => {
    if (disabled) {
      $event.preventDefault();
      return;
    }
    transformScale($event.currentTarget.childNodes[0], 1);
  };

  const mousemove = ($event) => {
    const width = window.innerWidth;
    if (disabled || width <= 568) {
      $event.preventDefault();
      return;
    }
    if ($event.currentTarget) {
      // Get the offset x & y of the mouse within that specific element
      var offsetX = $event.nativeEvent.offsetX;
      var offsetY = $event.nativeEvent.offsetY;

      // Get the height & width of the container div
      const height = $event.currentTarget.offsetHeight;
      const width = $event.currentTarget.offsetWidth;

      const XMULTIPLIER = 3 / height;
      const YMULTIPLIER = 3 / width;

      const MAX_X_DEG = 16 / width;
      const MAX_Y_DEG = 16 / width;

      var finalXRotation = 0;
      const calculatedXRotation =
        (XMULTIPLIER * (offsetY - height / 2)) / height;
      // We want to limit the max degrees of rotation for X
      if (calculatedXRotation > 0) {
        finalXRotation = -1 * Math.min(MAX_X_DEG, calculatedXRotation);
      } else {
        finalXRotation = -1 * Math.max(-1 * MAX_X_DEG, calculatedXRotation);
      }

      var finalYRotation = 0;
      // We want to limit the max degrees of rotation for Y
      const calculatedYRotation = (YMULTIPLIER * (offsetX - width / 2)) / width;
      if (calculatedYRotation > 0) {
        finalYRotation = Math.min(MAX_Y_DEG, calculatedYRotation);
      } else {
        finalYRotation = Math.max(-1 * MAX_Y_DEG, calculatedYRotation);
      }

      // Apply the transform to the first child div which will be the clickable container.
      transformRotation(
        $event.currentTarget.childNodes[0],
        finalXRotation,
        finalYRotation
      );
    }
  };

  const mouseleave = ($event) => {
    
    if (disabled) {
      $event.preventDefault();
      return;
    }
    transformScale($event.currentTarget.childNodes[0], 1);
    transformRotation($event.currentTarget.childNodes[0], 0, 0);
  };

  return (
    <div
      className={styles["clickable-div"]}
      onMouseDown={mousedown}
      onMouseUp={mouseup}
      onMouseMove={mousemove}
      onMouseLeave={mouseleave}
      onTouchEnd={mouseleave}
      onTouchStart={mousedown}
    >
      {children}
    </div>
  );
}
