"use client";
import Clickable from "../core/clickable";
import styles from "./radio.module.css";

export default function EssenceRadio(props) {
  return (
    <Clickable disabled={props.disabled}>
      <label
        className={`${styles["radio-container"]} ${
          props.disabled ? styles["radio-container-disabled"] : ""
        }`}
      >
        <input type="radio" {...props} />
        <div className={`${styles["radiomark"]} subtitle-1`} />
        {props.text}
      </label>
    </Clickable>
  );
}
