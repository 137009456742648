export const circlesKeys = {
  all: () => ["circles"],
  detail: (circleId) => [...circlesKeys.all(), circleId],
  participants: (circleId) => [...circlesKeys.all(), circleId, "participants"],
  updateParticipants: () => [...circlesKeys.all(), "participants", "update"],
  removeParticipants: () => [...circlesKeys.all(), "participants", "remove"],
  list: () => [...circlesKeys.all(), "list"],
  add: () => [...circlesKeys.all(), "add"],
  delete: () => [...circlesKeys.all(), "delete"],
};
