"use client";
import React, { useEffect, useState } from "react";
import EssenceButton from "../../../components/essence/button/button";
import { useNavigate } from "react-router-dom";
import Header from "../../../shared/components/header/page";
import Content from "../../../shared/components/content/page";
import useLoaderDisplay from "../../../shared/hooks/loader-display";
import { useMutation } from "@tanstack/react-query";
import { useCircleMutations } from "../../../shared/tanstack/circle/mutations";
import { onlineManager } from "@tanstack/react-query";
import EssenceAlert from "../../../components/essence/alert/alert";

export default function CircleInfo() {
  const navigate = useNavigate();
  const isOnline = onlineManager.isOnline();
  const [alert, setAlert] = useState({ show: false });

  // const [searchParams, setSearchParams] = useSearchParams();

  // const submitForm = (e) => {
  //   // We don't want the page to refresh
  //   e.preventDefault();

  //   searchParams.set("name", e.target["circle-name"].value);
  //   setSearchParams(searchParams);

  //   navigate(
  //     "/secure/onboarding/circle-interests" + "?" + searchParams.toString()
  //   );
  // };
  const { addCircleMutation } = useCircleMutations({});
  const {
    mutate: addCircle,
    isSuccess,
    isError,
    reset,
    status,
    isPaused,
    data,
  } = useMutation(addCircleMutation);

  useLoaderDisplay({
    key: "addCircle",
    isLoading: status === "pending" && !isPaused,
  });
  useEffect(() => {
    if (isSuccess) {
      navigate(`/secure/circles/${data.circleId}/participants/add`, {
        replace: true,
      });
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError) {
      setAlert({
        show: true,
        message:
          "There was some error creating the circle. Please try again later.",
      });
    }
  }, [isError]);

  const submitForm = async (e) => {
    // We don't want the page to refresh
    e.preventDefault();
    addCircle({
      interests: ["todo", "expenses"],
      type: "Travel",
      name: e.target["circle-name"].value,
    });
  };
  const hideAlertAndNavigateBack = () => {
    navigate(-1);
  };

  const hideAlert = () => {
    reset();
    setAlert({
      show: false,
    });
  };

  return (
    <>
      {!isOnline && (
        <EssenceAlert
          onOk={hideAlertAndNavigateBack}
          okButton="okay"
          message={"Please connect to the internet to create a circle."}
        ></EssenceAlert>
      )}

      {alert.show && (
        <EssenceAlert
          onOk={hideAlert}
          okButton="okay"
          message={alert.message}
        ></EssenceAlert>
      )}
      <Header
        title="Details"
        subtitle="What would you like to call your circle?"
      />
      <Content>
        <form onSubmit={submitForm}>
          <p className="headline-4">Name</p>
          <input
            essence-input="true"
            name="circle-name"
            // defaultValue={searchParams.get("name")}
          />
          <div className="mb-6"></div>
          <EssenceButton type="submit" name="add circle" />
        </form>
      </Content>
    </>
  );
}
