import { Link } from "react-router-dom";
import EssenceAnimatedRow from "../../../../components/essence/row/animated-row";
import { useEffect, useState } from "react";
import tokenService from "../../../../shared/services/token-service";
import { jwtDecode } from "jwt-decode";

export default function ListExpenses({ expenses, circleId }) {
  const [loggedInUserId, setLoggedInUserId] = useState("");

  const _getSubtitle = (expense) => {
    const paidBy = expense.creditors.map((x) => x.userId === loggedInUserId ? "You" : x.name).join(", ");
    return `<span>Date: ${expense.dateTime
      .toString()
      .replace(/T.*/, "")
      .split("-")
      .reverse()
      .join("-")}  <br>Paid By: ${paidBy}</span>`;
  };

  const _getSettlementSubtitle = (expense) => {
    return `Date: ${expense.dateTime
      .toString()
      .replace(/T.*/, "")
      .split("-")
      .reverse()
      .join("-")}`;
  };

  useEffect(() => {
    const accessToken = tokenService.getAccessToken();
    setLoggedInUserId(jwtDecode(accessToken).userId);
  }, []);

  const _getSettlementDescription = (expense) => {
    const paidBy = expense.creditors
      .map((x) => (x.userId === loggedInUserId ? "You" : x.name))
      .join(", ");
    const paidTo =
      expense.debtors
        ?.map((x) => (x.userId === loggedInUserId ? "you" : x.name))
        .join(", ") ?? "";
    return `Settlement: ${paidBy} paid ${paidTo}`;
  };
  return (
    <div>
      {expenses?.map((expense) => (
        <Link
          key={expense.expenseId}
          to={`/secure/expense/${expense.expenseId}`}
          state={{ circleId }}
        >
          {!expense.isSettlement && (
            <EssenceAnimatedRow
              title={expense.description}
              subTitle={_getSubtitle(expense)}
              actionText={`${expense.currency}${expense.amount}`}
            />
          )}
          {expense.isSettlement && (
            <EssenceAnimatedRow
              title={_getSettlementDescription(expense)}
              subTitle={_getSettlementSubtitle(expense)}
              actionText={`${expense.currency}${expense.amount}`}
            />
            // <div className="headline-4 my-8">{_getSettlementDescription(expense)}</div>
          )}
        </Link>
      ))}
    </div>
  );
}
