import { useEffect, useState } from "react";
import styles from "./todo.module.css";
import Animated from "../../../../components/essence/animated/animated.jsx";
import EssenceButton from "../../../../components/essence/button/button.jsx";
import ListTodo from "./list-todo.jsx";
import useLoaderDisplay from "../../../../shared/hooks/loader-display.jsx";
import { useTodoMutations } from "../../../../shared/tanstack/todos/mutations.jsx";
import { useTodoQueries } from "../../../../shared/tanstack/todos/queries.jsx";
import { useQuery, useMutation } from "@tanstack/react-query";
import Clickable from "../../../../components/essence/core/clickable.jsx";
import { Share } from "@capacitor/share";
import tokenService from "../../../../shared/services/token-service";
import { jwtDecode } from "jwt-decode";
import { BranchDeepLinks } from "capacitor-branch-deep-links";

export default function Todo(props) {
  const circleId = props.circleId;
  const [newTodo, setNewTodo] = useState("");

  const { toggleToDoMutation, addToDoMutation, deleteToDoMutation } =
    useTodoMutations({ circleId });
  const { mutate: toggleToDo } = useMutation(toggleToDoMutation);
  const { mutate: addToDo } = useMutation(addToDoMutation);
  const { mutate: deleteToDo } = useMutation(deleteToDoMutation);

  const { toDoQuery } = useTodoQueries(circleId);
  const { data: todos, isLoading } = useQuery(toDoQuery);
  useLoaderDisplay({ key: "getToDos", isLoading });

  const [activeTodos, setActiveTodos] = useState(
    todos?.filter((todo) => todo.status === "PENDING") ?? []
  );
  const [completedTodos, setCompletedTodos] = useState(
    todos?.filter((todo) => todo.status === "COMPLETED") ?? []
  );

  useEffect(() => {
    if (todos) {
      setActiveTodos(todos?.filter((todo) => todo.status === "PENDING"));
      setCompletedTodos(todos?.filter((todo) => todo.status === "COMPLETED"));
    }
  }, [todos]);

  const handleAddTodo = async (event) => {
    event.preventDefault();
    addToDo({
      circleId,
      name: newTodo,
    });

    setNewTodo("");
  };

  const handleToggleCompletion = (toDoId) => {
    toggleToDo({ toDoId, circleId });
  };

  const handleRemoveTodo = (toDoId) => {
    deleteToDo({ toDoId, circleId });
  };

  const shareToDos = async () => {
    var shareText = "";
    activeTodos.forEach((todo) => {
      shareText += `[   ]  ${todo.name}\n`;
    });

    completedTodos.forEach((todo) => {
      shareText += `[✓]  ${todo.name}\n`;
    });

    shareText += `\n`;
    shareText += `Here is a list of todos for ${props.circleName}. Try out Circles to manage your todos, expense and much more.\n`;

    var url = "https://circlesco.app.link/share";
    if (Capacitor.getPlatform() !== "web") {
      // optional fields
      const accessToken = tokenService.getAccessToken();
      const loggedInUserId = jwtDecode(accessToken).userId;
      var analytics = {
        channel: "native",
        feature: "referral",
        campaign: "referral for pr",
        stage: "new user",
      };
      const properties = {
        referrerId: loggedInUserId,
      };
      // share sheet
      const res = await BranchDeepLinks.generateShortUrl({
        analytics,
        properties,
        shareText,
      });
      url = res.url;
    }

    await Share.share({ url: url, text: shareText });
  };

  return (
    <>
      <Animated>
        <form onSubmit={handleAddTodo} className="">
          <div className={styles["new-todo"]}>
            <input
              essence-input="true"
              type="text"
              placeholder="add todo"
              name="todo-item"
              value={newTodo}
              onChange={(event) => setNewTodo(event.target.value)}
            />

            <div className="flex mb-6 gap-5 justify-center items-center">
              <Clickable key="add-expenses">
                <Animated>
                  <div
                    className="flex headline-4 lowercase"
                    onClick={shareToDos}
                  >
                    <div className="fa fa-share me-3"></div>
                    <span>share list</span>
                  </div>
                </Animated>
              </Clickable>
              <EssenceButton
                onClick={handleAddTodo}
                name="Add TODO"
                disabled={newTodo.trim() === ""}
              />
            </div>
          </div>
        </form>
      </Animated>
      <ListTodo
        todos={activeTodos}
        setTodos={setActiveTodos}
        onToggle={handleToggleCompletion}
        onRemove={handleRemoveTodo}
      />

      <ListTodo
        todos={completedTodos}
        setTodos={setCompletedTodos}
        onToggle={handleToggleCompletion}
        onRemove={handleRemoveTodo}
      />
    </>
  );
}
