import { useQueryClient } from "@tanstack/react-query";
import api from "../../services/api";
import { accountKeys } from "./keys";
import { getItems } from "../query-response-manager";

export const useAccountQueries = () => {
  const queryClient = useQueryClient();
  const accountQuery = {
    queryKey: accountKeys.all(),
    queryFn: () =>
      api.get(`/api/account`).then((res) => {
        const serverItems = res.data;
        const clientItems = queryClient.getQueryData(accountKeys.all());
        const items = getItems(
          [serverItems],
          clientItems ? [clientItems] : [],
          "userId"
        );
        return items[0];
      }),
    refetchOnReconnect: false,
  };

  return {
    accountQuery,
  };
};
