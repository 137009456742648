import { motion } from "framer-motion";

export default function AnimatedParent({ children, key }) {
  const parentVariants = {
    initial: {},
    animate: {
      transition: { staggerChildren: 0.04 },
    },
    exit: {
      transition: { staggerChildren: 0.04 },
    },
  };

  return (
    <motion.div
      variants={parentVariants}
      animate="animate"
      initial="initial"
      exit="exit"
    >
      {children}
    </motion.div>
  );
}
