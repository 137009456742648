import { addExpense, updateExpense, deleteExpense } from "./api.jsx";
import { expensesKeys } from "./keys.jsx";

export const setDefaultExpensesMutation = (queryClient) => {
  // we need a default mutation function so that paused mutations can resume after a page reload
  queryClient.setMutationDefaults(expensesKeys.add(), {
    mutationFn: async (expense) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({
        queryKey: expensesKeys.add(),
      });
      return await addExpense(expense);
    },
  });

  // we need a default mutation function so that paused mutations can resume after a page reload
  queryClient.setMutationDefaults(expensesKeys.delete(), {
    mutationFn: async (expense) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({
        queryKey: expensesKeys.delete(),
      });
      const expenseToUpdate = _getCachedExpense(expense);
      return await deleteExpense(expenseToUpdate);
    },
  });

  // we need a default mutation function so that paused mutations can resume after a page reload
  queryClient.setMutationDefaults(expensesKeys.update(), {
    mutationFn: async (expense) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({
        queryKey: expensesKeys.update(),
      });
      const expenseToUpdate = _getCachedExpense(expense);
      return await updateExpense(expenseToUpdate);
    },
  });

  const _getCachedExpense = (expense) => {
    const expenses = queryClient.getQueryData(
      expensesKeys.list(expense.circleId)
    );
    const updatedExpense = expenses?.filter(
      (x) => x.clientToDoId === expense.expenseId
    );
    return updatedExpense?.length > 0 ? updatedExpense[0] : expense;
  };
};
