import { useEffect, useState } from "react";
import styles from "./date-picker.module.css";
import EssencePickerList from "../core/picker-list/picker-list";
import EssencePickerOverlay from "../core/picker-overlay/picker-overlay";

("use client");
export default function EssenceDatePicker({
  date,
  minDate,
  maxDate,
  onClose
}) {
  minDate = minDate ? minDate : new Date(1900, 1, 1);
  maxDate = maxDate ? maxDate : new Date(2050, 12, 1);

  const [currentDate, setCurrentDate] = useState(date ?? new Date());
  const [internalDate, setInternalDate] = useState(date ?? new Date());
  var [hidePicker, setHidePicker] = useState(true);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dayNames = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [dateOption, setDateOption] = useState({});
  const [monthOption, setMonthOption] = useState({});
  const [yearOption, setYearOption] = useState({});

  const [dates, setDates] = useState([]);
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const accept = () => {
    setHidePicker(true);
    setCurrentDate(new Date(internalDate.getTime()));
  };

  const reject = () => {
    setHidePicker(true);
    onClose();
  };

  const openDatePicker = (event) => {
    event?.preventDefault();
    setHidePicker(false);
  };

  useEffect(() => {
    if (minDate && maxDate) {
      _updateOptions(minDate, maxDate);
    }
  }, [internalDate]);

  useEffect(() => {
    _checkdate();
  }, [months, years, monthOption, dateOption]);

  useEffect(() => {
    if (dateOption?.value !== dates[internalDate.getDate() - 1]?.value) {
      setDateOption(dates[internalDate.getDate() - 1]);
    }
    if (monthOption?.value !== months[internalDate.getMonth()]?.value) {
      setMonthOption(months[internalDate.getMonth()]);
    }
    if (
      maxDate &&
      yearOption?.value !==
        years[maxDate.getFullYear() - internalDate.getFullYear()]?.value
    ) {
      setYearOption(years[maxDate.getFullYear() - internalDate.getFullYear()]);
    }
  }, [dates]);

  const onDateChange = ($event) => {
    const date = $event;
    setDateOption(date);
    internalDate.setDate(date.value);
    setInternalDate(internalDate);
  };

  const onMonthChange = ($event) => {
    const month = $event;
    internalDate.setMonth(month.index);
    setInternalDate(internalDate);
    setMonthOption(month);
  };

  const onYearChange = ($event) => {
    const year = $event;
    internalDate.setFullYear(year.value);
    setInternalDate(internalDate);
    setYearOption(year);
  };

  const _checkdate = () => {
    var numberOfDays = 31;
    const thirtyOneDaysMonths = [1, 3, 5, 7, 8, 10, 12];
    const isThirtyOneDaysMonth = thirtyOneDaysMonths.includes(
      monthOption?.value
    );
    if (isThirtyOneDaysMonth) {
      numberOfDays = 31;
    } else if (monthOption?.value == 2) {
      if (
        (yearOption?.value % 4 == 0 && yearOption?.value % 100 != 0) ||
        yearOption?.value % 400 == 0
      ) {
        numberOfDays = 29;
      } else {
        numberOfDays = 28;
      }
    } else {
      numberOfDays = 30;
    }
    var localDates = [];

    for (let index = 1; index <= numberOfDays; index++) {
      localDates.push({
        index: index - 1,
        title: index.toString(),
        value: index,
      });
    }
    setDates(localDates);
  };

  const _updateOptions = (minDate, maxDate) => {
    var years = [];
    var months = [];
    for (
      let index = maxDate.getFullYear();
      index >= minDate.getFullYear();
      index--
    ) {
      years.push({
        index: maxDate.getFullYear() - index,
        title: index.toString(),
        value: index,
      });
    }

    setYears(years);

    for (let index = 1; index <= 12; index++) {
      months.push({
        index: index - 1,
        title: index.toString(),
        subtitle: monthNames[index - 1],
        value: index,
      });
    }

    setMonths(months);
  };

  return (
    <div className={styles.container}>
      <input
        essence-input="true"
        onFocus={openDatePicker}
        value={currentDate
          ?.toISOString()
          .replace(/T.*/, "")
          .split("-")
          .reverse()
          .join("-")}
        readOnly
        inputMode="none"
      />
      {!hidePicker && (
        <EssencePickerOverlay
          id="overlay-date-picker"
          onAccept={accept}
          onReject={reject}
        >
          <div className={styles["picker-container"]}>
            <EssencePickerList
              id="date-picker-days-id"
              value={dateOption}
              onChange={onDateChange}
              className={styles["picker-list"]}
              options={dates}
            ></EssencePickerList>

            <EssencePickerList
              id="date-picker-months-id"
              value={monthOption}
              onChange={onMonthChange}
              className={styles["picker-list"]}
              options={months}
            ></EssencePickerList>

            <EssencePickerList
              id="date-picker-years-id"
              value={yearOption}
              onChange={onYearChange}
              className={styles["picker-list"]}
              options={years}
            ></EssencePickerList>
          </div>
        </EssencePickerOverlay>
      )}
    </div>
  );
}
