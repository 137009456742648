import React, { useState } from "react";
import EssenceAnimatedButton from "../../../../components/essence/button/animated-button";
import Header from "../../../../shared/components/header/page";
import Content from "../../../../shared/components/content/page";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import EssenceDatePicker from "../../../../components/essence/date-picker/date-picker";
import { useEffect } from "react";

const AddItineraryDetail = () => {
  const navigate = useNavigate();
  const { circleId } = useParams();
  const [itineraryDetail, setItineraryDetail] = useState({
    title: "",
    startDateTime: new Date(),
    endDateTime: new Date(),
    description: "",
    origin:"",
    destination:"",
    originLatLng: [0],
    destinationLatLng: [0],
    durationHour: "0",
    durationMin: "0",
    durationDay: "0"
  });

  useEffect(() => {
    const itineraryData = JSON.parse(localStorage.getItem(`itinerary-detail`));
    if (itineraryData) {
      setItineraryDetail(itineraryData);
    }
  }, []);

  const goToOrgDest = (e) => {
    e.preventDefault();
    localStorage.setItem(`itinerary-detail`, JSON.stringify(itineraryDetail));
    navigate(`/secure/circles/${circleId}/add-itinerary/org-dest`, {
      state: { itineraryDetail },
    });
  };

  const form = {
    get isDisabled() {
      return !itineraryDetail.title 
        ? true
        : undefined;
    },
  };

  return (
    <>
      <Header title="Details" subtitle="Enter the details of the itinerary." />
      <Content>
        <input
          essence-input="true"
          type="text"
          placeholder="Title"
          value={itineraryDetail.title}
          onChange={(event) =>
            setItineraryDetail({
              ...itineraryDetail,
              title: event.target.value,
            })
          }
          name="itinerary-item"
        />
        <EssenceDatePicker
          date={itineraryDetail.startDateTime}
          onChange={(event) =>
            setItineraryDetail({
              ...itineraryDetail,
              startDateTime: event.target.value.toISOString(),
            })
          }
        ></EssenceDatePicker>
        <EssenceDatePicker
          date={itineraryDetail.endDateTime}
          onChange={(event) =>
            setItineraryDetail({
              ...itineraryDetail,
              endDateTime: event.target.value.toISOString(),
            })
          }
        ></EssenceDatePicker>
        <input
          essence-input="true"
          type="text"
          placeholder="description"
          value={itineraryDetail.description}
          onChange={(event) =>
            setItineraryDetail({
              ...itineraryDetail,
              description: event.target.value,
            })
          }
          name="itinerary-item"
        />
        <EssenceAnimatedButton
          disabled={form.isDisabled}
          onClick={goToOrgDest}
          name="next"
        />
      </Content>
    </>
  );
};

export default AddItineraryDetail;
