import React, { useState, useEffect } from "react";
import EssenceAnimatedButton from "../../../../components/essence/button/animated-button";
import Header from "../../../../shared/components/header/page";
import Content from "../../../../shared/components/content/page";
import EssenceDatePicker from "../../../../components/essence/date-picker/date-picker";
import styles from "./add-expense.module.css";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import storageKeyService from "../../../../shared/services/storage-key-service";

const AddExpenseDetail = () => {
  const [searchParams] = useSearchParams();

  const storageKey = searchParams.get("token");

  const navigate = useNavigate();
  const location = useLocation();
  const { circleId } = useParams();

  const expenseFromState = location.state?.expenseDetail;
  if (expenseFromState) {
    expenseFromState.dateTime = new Date(expenseFromState?.dateTime);
  }
  const existingExpense = storageKeyService.getExpenseForKey(storageKey);

  if (existingExpense) {
    existingExpense.dateTime = new Date(existingExpense.dateTime);
  }

  const [expenseDetail, setExpenseDetail] = useState(
    existingExpense
      ? existingExpense
      : expenseFromState
      ? expenseFromState
      : {
          amount: "",
          description: "",
          dateTime: new Date(),
          isSettlement: false,
        }
  );

  useEffect(() => {
    storageKeyService.removeExpensesExceptKey(storageKey);
    if (location.state === null || location.state.expenseDetail === null) {
      navigate(-1);
    }
  }, []);

  const goToPayee = (e) => {
    e.preventDefault();
    if(expenseDetail.creditors){
      delete expenseDetail.creditors;
    }
    expenseDetail.currency = "₹";
    storageKeyService.setExpenseForKey(storageKey, expenseDetail);
    navigate(`/secure/circles/${circleId}/add-expense/payee`, {
      state: { expenseDetail, storageKey },
    });
  };

  const form = {
    get isDisabled() {
      return !expenseDetail.amount || !expenseDetail.description
        ? true
        : undefined;
    },
  };

  const onAmountChange = ($event) => {
    var regex = new RegExp(/^[0-9]*\.?[0-9]{0,2}?$/);
    let amount = $event.target.value;

    if (regex.test(amount)) {
      setExpenseDetail({ ...expenseDetail, amount: parseFloat(amount) || 0 });
    }
  };

  const onBlur = () => {
    if (expenseDetail.amount) {
      setExpenseDetail({
        ...expenseDetail,
        amount: parseFloat(expenseDetail.amount),
      });
    }
  };
  return (
    <>
      <Header title="Details" subtitle={`Enter the details of the expense.`} />
      <Content>
        <div>
          <div className="headline-5 mt-3">Description</div>
          <input
            essence-input="true"
            type="text"
            value={expenseDetail.description}
            onChange={(event) =>
              setExpenseDetail({
                ...expenseDetail,
                description: event.target.value,
              })
            }
            name="expense-item"
          />
        </div>
        <div className="headline-5 mt-3">Date</div>
        <EssenceDatePicker
          date={expenseDetail.dateTime}
          onChange={(event) =>
            setExpenseDetail({
              ...expenseDetail,
              dateTime: event.target.value.toISOString(),
            })
          }
        ></EssenceDatePicker>
        <div className="mb-6">
          <div className="headline-5 mt-3">Amount</div>
          <input
            className={styles.amount}
            onBlur={onBlur}
            essence-input="true"
            value={expenseDetail.amount}
            onChange={onAmountChange}
            name="expense-amount"
            type="tel"
          />
        </div>

        <EssenceAnimatedButton
          onClick={goToPayee}
          name="next"
          disabled={form.isDisabled}
        />
      </Content>
    </>
  );
};

export default AddExpenseDetail;
