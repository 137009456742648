import EssenceAnimatedButton from "../../../../components/essence/button/animated-button";
import EssenceAnimatedCheckbox from "../../../../components/essence/checkbox/animated-checkbox";
import Header from "../../../../shared/components/header/page";
import Content from "../../../../shared/components/content/page";
import styles from "./add-expense.module.css";
import { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import tokenService from "../../../../shared/services/token-service";
import { jwtDecode } from "jwt-decode";
import getMemberType from "../../member-type";
import { useCircleQueries } from "../../../../shared/tanstack/circle/queries";
import { useQuery } from "@tanstack/react-query";
import storageKeyService from "../../../../shared/services/storage-key-service";

const Payee = () => {
  const accessToken = tokenService.getAccessToken();
  const loggedInUserId = jwtDecode(accessToken).userId;
  const { circleId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { circleQuery, participantsQuery } = useCircleQueries({ circleId });
  const { data: circle } = useQuery(circleQuery);
  const { data: circleParticipants } = useQuery(participantsQuery);
  const { storageKey } = location.state;
  const expenseDetail = storageKeyService.getExpenseForKey(storageKey) 
    ? storageKeyService.getExpenseForKey(storageKey) 
    : location.state.expenseDetail;
  const [participants, setParticipants] = useState(circleParticipants);
  const member = getMemberType(circle?.type)?.pluralTitle ?? "";
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    const accessToken = tokenService.getAccessToken();
    if (circleParticipants && accessToken) {
      const updatedData = circleParticipants.map((participant) => {
        const isChecked = expenseDetail.creditors 
        ? expenseDetail.creditors.some(creditor => creditor.userId === participant.userId)
        : participant.userId === loggedInUserId ;
        const newAmount = 
        (expenseDetail.creditors && expenseDetail.creditors.find(creditor => creditor.userId === participant.userId)) 
        ? expenseDetail.creditors.find(creditor => creditor.userId === participant.userId).amount 
        : (isChecked ? expenseDetail.amount : 0);
        return {
          ...participant,
          isChecked,
          amount: newAmount,
        };
      });
      setParticipants(updatedData);
    }
  }, [circleParticipants]);

  const debitAmount = participants?.reduce(
    (sum, participant) => sum + (parseFloat(participant.amount) || 0),
    0
  );

  const goToParticipants = () => {
    const creditors = participants.filter(
      (participant) => participant.amount > 0
    );
    expenseDetail.creditors = creditors;
    storageKeyService.setExpenseForKey(storageKey, expenseDetail);
    navigate(`/secure/circles/${circleId}/add-expense/participants`, {
      state: { expenseDetail },
    });
  };

  const onToggle = (userId) => {
    let updatedParticipants = participants?.map((participant) => {
      if (participant.userId === userId) {
        return { ...participant, isChecked: !participant.isChecked, amount: 0 };
      }
      return participant;
    });

    if(!isDirty){
      const checkedUsers = updatedParticipants.filter((participant) => participant.isChecked);
      const amountToDistribute = parseFloat((expenseDetail.amount / checkedUsers.length).toFixed(2));
      updatedParticipants = updatedParticipants.map((participant) => ({
        ...participant,
        amount: participant.isChecked ? amountToDistribute : 0,
      }));
      
      if(checkedUsers.length === participants.length){
        const debitAmount = updatedParticipants?.reduce(
          (sum, participant) => sum + (parseFloat(participant.amount) || 0),
          0
        );
        const remainingAmount = expenseDetail.amount - debitAmount;
        if(remainingAmount !== 0){
          const randomIndex = Math.floor(
            Math.random() * circleParticipants?.length
          );
          updatedParticipants = updatedParticipants.map((participant, index) => {
            if (index === randomIndex) {
              return {
                ...participant,
                amount: (amountToDistribute + remainingAmount).toFixed(2),
              };
            } else {
              return participant;
            }
          })
        }
      }
    }
    setParticipants(updatedParticipants);
  };

  const onAmountChange = ($event, userId) => {
    setIsDirty(true);
    var regex = new RegExp(/^[0-9]*\.?[0-9]{0,2}?$/);
    let amount = $event.target.value;

    if (regex.test(amount)) {
      const updatedParticipants = participants?.map((participant) => {
        if (participant.userId === userId) {
          return { ...participant, amount };
        }
        return participant;
      });
      setParticipants(updatedParticipants);
    }
  };
  const onBlur = () => {
    const updatedParticipants = participants?.map((participant) => {
      if (participant.amount) {
        return { ...participant, amount: parseFloat(participant.amount) };
      }
      return participant;
    });
    setParticipants(updatedParticipants);
  };

  const form = {
    get isDisabled() {
      return expenseDetail.amount - debitAmount !== 0 ? true : undefined;
    },
  };

  return (
    <>
      <Header
        title="Paid by"
        subtitle={`Select the list of ${member} who paid for the expense.`}
      />
      <Content>
        <ul>
          {participants?.map((participant) => (
            <li
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <EssenceAnimatedCheckbox
                title={
                  participant.userId === loggedInUserId
                    ? "You"
                    : participant.name
                }
                checked={participant.isChecked}
                onChange={() => onToggle(participant.userId)}
              />
              {participant.isChecked && (
                <span className="headline-4 me-3">
                  {expenseDetail.currency}
                </span>
              )}
              {participant.isChecked && (
                <input
                  onBlur={onBlur}
                  className={styles.amount}
                  essence-input="true"
                  value={participant.amount}
                  onChange={(e) => onAmountChange(e, participant.userId)}
                  type="tel"
                ></input>
              )}
            </li>
          ))}
          {form.isDisabled && (
            <p className={styles.message}>
              Note: Remaining balance to match{" "}
              {(expenseDetail.amount - debitAmount).toFixed(2)}
            </p>
          )}
          <EssenceAnimatedButton
            onClick={goToParticipants}
            name="next"
            disabled={form.isDisabled}
          />
        </ul>
      </Content>
    </>
  );
};

export default Payee;
