import { circlesKeys } from "./keys.jsx";
import {
  addCircle,
  addParticipants,
  deleteCircle,
  removeParticipants,
} from "./api.jsx";

export const setDefaultCircleMutations = (queryClient) => {
  // we need a default mutation function so that paused mutations can resume after a page reload
  queryClient.setMutationDefaults(circlesKeys.add(), {
    mutationFn: async (circle) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({
        queryKey: circlesKeys.add(),
      });
      return await addCircle(circle);
    },
  });

  // we need a default mutation function so that paused mutations can resume after a page reload
  queryClient.setMutationDefaults(circlesKeys.delete(), {
    mutationFn: async (circle) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({
        queryKey: circlesKeys.delete(),
      });
      return await deleteCircle(circle);
    },
  });

  // we need a default mutation function so that paused mutations can resume after a page reload
  queryClient.setMutationDefaults(circlesKeys.updateParticipants(), {
    mutationFn: async (participant) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({
        queryKey: circlesKeys.updateParticipants(),
      });
      return await addParticipants(participant);
    },
  });

  // we need a default mutation function so that paused mutations can resume after a page reload
  queryClient.setMutationDefaults(circlesKeys.removeParticipants(), {
    mutationFn: async ({ circleId, contacts }) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({
        queryKey: circlesKeys.updateParticipants(),
      });
      const userIds = contacts.map((participant) => participant.userId);
      return await removeParticipants({ circleId, participantId: userIds[0] });
    },
  });
};
