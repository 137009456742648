const _listeners = [];
import Lottie from "lottie-web";
var currentAnimation = undefined;

const reset = () => {
  Object.keys(localStorage).forEach((key) => {
    if (key.indexOf("hide-coach-mark-") === 0) localStorage.removeItem(key);
  });
};

const hideCoachMark = (key) => {
  localStorage.setItem(`hide-coach-mark-${key}`, true);
};

const shouldShowCoachMark = (key) => {
  const value = localStorage.getItem(`hide-coach-mark-${key}`);
  return value !== "true";
};

const addGestureListener = ({ listener, animation }) => {
  if (listener) {
    _listeners.push(listener);
    window.addEventListener("wheel", listener);
    window.addEventListener("touchmove", listener);
    currentAnimation = Lottie.loadAnimation({
      container: document.querySelector(animation.elementSelector),
      animationData: animation.lottie,
    });
  }
};

const removeGestureListener = (listener) => {
  if (listener) {
    _listeners.push(listener);
    if (currentAnimation) {
      currentAnimation.destroy();
    }
    window.addEventListener("wheel", listener);
    window.addEventListener("touchmove", listener);
  }
};

export default {
  reset,
  hideCoachMark,
  addGestureListener,
  shouldShowCoachMark,
  removeGestureListener,
};
