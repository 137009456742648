"use client";
import React, { useEffect, useState } from "react";
import EssenceAnimatedSwitch from "../../../../components/essence/switch/animated-switch";
import Header from "../../../../shared/components/header/page";
import Content from "../../../../shared/components/content/page";
import styles from "./page.module.css";
import themeService from "../../../../shared/services/theme-service.js";

export default function Theme() {
  const [isDarkMode, setDarkMode] = useState(themeService.isDarkMode());
  const [themeColors, setThemeColors] = useState(themeService.getColors());

  useEffect(() => {
    if (isDarkMode) {
      themeService.setDarkMode(isDarkMode);
    } else {
      themeService.setLightMode(isDarkMode);
    }
  }, [isDarkMode]);

  useEffect(() => {
    themeService.setColors(themeColors);
  }, [themeColors]);

  const onToggleDarkMode = () => {
    document.documentElement.classList.toggle("theme-light");
    setDarkMode(!isDarkMode);
  };

  const changeColor = (themeColor) => {
    const updatedColors = themeColors.map((color) => ({
      ...color,
      isSelected: themeColor.id === color.id,
    }));
    setThemeColors(updatedColors);

    document.documentElement.setAttribute(
      "style",
      `--theme-color: ${themeColor.color}`
    );
  };
  return (
    <>
      <Header title="Theme" subtitle="customise the theme to your liking" />
      <Content>
      <p className="headline-5">Dark Mode</p>
        <EssenceAnimatedSwitch
          text={""}
          checked={isDarkMode}
          onChange={() => onToggleDarkMode()}
        />
        <p className="headline-5 mt-6">Color</p>
        <div className="flex gap-3 mt-3">
          {themeColors.map((themeColor) => {
            return (
              <div
                style={{ backgroundColor: themeColor.color }}
                className={`${styles["color-picker"]} ${
                  themeColor.isSelected ? styles["active"] : ""
                }`}
                onClick={() => {
                  changeColor(themeColor);
                }}
                key={themeColor.color}
              />
            );
          })}
        </div>
      </Content>
    </>
  );
}
