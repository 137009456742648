import { useNavigate, useSearchParams } from "react-router-dom";
import ListExpenses from "./list-expenses.jsx";
import EssenceAnimatedButton from "../../../../components/essence/button/animated-button.jsx";
import { useExpenseQueries } from "../../../../shared/tanstack/expenses/queries.jsx";
import { useQuery } from "@tanstack/react-query";
import useLoaderDisplay from "../../../../shared/hooks/loader-display.jsx";
import { useCircleQueries } from "../../../../shared/tanstack/circle/queries.jsx";
import Clickable from "../../../../components/essence/core/clickable.jsx";
import Animated from "../../../../components/essence/animated/animated.jsx";
import tokenService from "../../../../shared/services/token-service";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import storageKeyService from "../../../../shared/services/storage-key-service.js";

export default function Expenses(props) {
  const navigate = useNavigate();
  const [loggedInUserId, setLoggedInUserId] = useState("");
  const [searchParams] = useSearchParams();
  const storageKey = searchParams.get("token");

  const { expensesQuery, expensesSummaryQuery } = useExpenseQueries({
    circleId: props.circleId,
  });

  const { participantsQuery } = useCircleQueries({
    circleId: props.circleId,
  });

  const { data: expenses, isLoading } = useQuery(expensesQuery);
  useLoaderDisplay({ key: "getExpenses", isLoading });
  const { data: expensesSummary } = useQuery(expensesSummaryQuery);
  const { data: participants } = useQuery(participantsQuery);

  useEffect(() => {
    const accessToken = tokenService.getAccessToken();
    setLoggedInUserId(jwtDecode(accessToken).userId);
  }, []);

  const goToAddExpenses = () => {
    navigate(
      `/secure/circles/${
        props.circleId
      }/add-expense/expense-detail?token=${new Date().getTime()}`,
      {
        state: {},
      }
    );
  };

  const goToSettleUp = () => {
    storageKeyService.removeSettlementsExceptKey(storageKey);
    const settlementDetail = {
      ...expensesSummary[0],
      dateTime: new Date(),
      circleId: props.circleId,
      description: "Settlement",
    };
    navigate(
      `/secure/circles/${
        props.circleId
      }/add-settlement/payee?token=${new Date().getTime()}`,
      {
        state: { settlementDetail },
      }
    );
  };
  const _findParticipant = (userId) => {
    if (participants) {
      const filteredParticipant = participants.find((x) => x.userId == userId);
      return filteredParticipant;
    }
    return {};
  };

  return (
    <>
      <Clickable key="add-expenses">
        <Animated>
          <div className="headline-4 lowercase" onClick={goToAddExpenses}>
            + add expense
          </div>
        </Animated>
      </Clickable>
      <div className="mt-3" />
      {/* <EssenceAnimatedButton name="Add Expense" onClick={goToAddExpenses} /> */}
      {expensesSummary?.length > 0 && (
        <div className="mb-6 gap-20 items-center">
          {expensesSummary?.map((summary) => (
            <div>
              <span
                style={{
                  color:
                    summary.creditorId !== loggedInUserId
                      ? "rgb(220 120 120)"
                      : "#73b873",
                }}
              >
                {summary.creditorId !== loggedInUserId ? "Pay" : "Collect from"}
              </span>
              {"  "}
              {
                _findParticipant(
                  summary.creditorId !== loggedInUserId
                    ? summary.creditorId
                    : summary.debtorId
                )?.name
              }{" "}
              <span className="headline-4">
                {summary.currency}
                {summary.amount}
              </span>
            </div>
          ))}

          <EssenceAnimatedButton name="settle up" onClick={goToSettleUp} />
        </div>
      )}
      <ListExpenses expenses={expenses} circleId={props.circleId} />
    </>
  );
}
