"use client";
import Clickable from "../core/clickable";
import styles from "./switch.module.css";

export default function EssenceSwitch(props) {
  return (
    <Clickable disabled={props.disabled}>
      <label
        className={`${styles["switch"]} ${
          props.disabled ? styles["switch-disabled"] : ""
        }`}
      >
        <input type="checkbox" {...props} />
        <div className={`${styles["slider"]}`}>
          <span className={`${styles["inner"]}`}></span>
        </div>
        <div className="headline-4">{props.text}</div>
      </label>
    </Clickable>
  );
}
