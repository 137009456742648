import AnimatedParent from "../../../../components/essence/animated/animated-parent";
import Page from "../../../../components/essence/page/page";
import EssenceAnimatedButton from "../../../../components/essence/button/animated-button";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import styles from "../expenses/expenses.module.css";
import { useEffect, useState } from "react";
import { useExpenseQueries } from "../../../../shared/tanstack/expenses/queries";
import { useExpenseMutations } from "../../../../shared/tanstack/expenses/mutations";
import useLoaderDisplay from "../../../../shared/hooks/loader-display";
import { useQuery, useMutation } from "@tanstack/react-query";
import { jwtDecode } from "jwt-decode";
import tokenService from "../../../../shared/services/token-service";

export default function ExpenseDetail() {
  const { expenseId } = useParams();
  const location = useLocation();
  const { circleId } = location.state;
  const { expenseQuery } = useExpenseQueries({ expenseId });
  const { data: expense, isLoading } = useQuery(expenseQuery);
  const navigate = useNavigate();
  const [isCreditor, setIsCreditor] = useState(false);
  const accessToken = tokenService.getAccessToken();
  const loggedInUserId = jwtDecode(accessToken).userId;
  const { deleteExpenseMutation } = useExpenseMutations({
    circleId,
    expenseId,
  });
  const {
    mutate: deleteExpense,
    isPaused,
    status,
    isSuccess,
  } = useMutation(deleteExpenseMutation);

  useLoaderDisplay({
    key: "getExpense",
    isLoading: isLoading,
  });
  useLoaderDisplay({
    key: "deleteExpense",
    isLoading: status === "pending" && !isPaused,
  });

  const onEditExpense = () => {
    if (expense?.isSettlement) {
      navigate(
        `/secure/circles/${circleId}/add-settlement/payee?token=${new Date().getTime()}`,
        {
          state: {
            settlementDetail: {
              ...expense,
              debtorId: expense.debtors[0].entityId,
              creditorId: expense.creditors[0].entityId,
            },
          },
        }
      );
    } else {
      navigate(
        `/secure/circles/${circleId}/add-expense/expense-detail?token=${new Date().getTime()}`,
        {
          state: { expenseDetail: expense },
        }
      );
    }
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  const onDeleteExpense = () => {
    deleteExpense({ expenseId, circleId });
  };

  useEffect(() => {
    setIsCreditor(expense?.createdBy === loggedInUserId);
  }, [expense]);
  return (
    <AnimatedParent>
      <Page title={!expense?.isSettlement ? "expense detail" : "settlement"}>
        {!expense?.isSettlement && <p className="headline-5">Description</p>}
        {!expense?.isSettlement && (
          <p className="headline-4">{expense?.description}</p>
        )}

        <p className="headline-5  mt-6">Amount</p>
        <p className="headline-4">
          {expense?.currency}
          {expense?.amount}
        </p>

        <p className="headline-5 mt-6">Date</p>
        <p className="headline-4">
          {expense?.dateTime.replace(/T.*/, "").split("-").reverse().join("-")}
        </p>
        <div className="mt-10"></div>

        {!expense?.isSettlement && (
          <div className="mt-6">
            {expense?.creditors.map((user) => (
              <p key={user.userId} className="headline-4">
                {loggedInUserId !== user.userId ? user.name : "You"} paid{" "}
                {expense?.currency}
                {user?.amount}{" "}
              </p>
            ))}

            {expense?.debtors.map((user) =>
              loggedInUserId !== user.userId ? (
                <p key={user.userId} className="headline-4 ms-6">
                  - {user.name} owes {expense?.currency}
                  {user?.amount}{" "}
                </p>
              ) : (
                <p key={user.userId} className="headline-4 ms-6">
                  - You owe {expense?.currency}
                  {user?.amount}{" "}
                </p>
              )
            )}
          </div>
        )}

        {expense?.isSettlement && (
          <div className="mt-6">
            {expense?.creditors.map((user) => (
              <p key={user.userId} className="headline-4">
                {loggedInUserId !== user.userId ? user.name : "You"} paid{" "}
                {expense?.debtors.map((user) =>
                  loggedInUserId !== user.userId ? `${user.name} ` : "you "
                )}
                {expense?.currency}
                {user?.amount}
              </p>
            ))}
          </div>
        )}

        <div className="mt-6" />
        {isCreditor && (
          <div className={styles["edit-expense"]}>
            <EssenceAnimatedButton
              name="edit"
              onClick={onEditExpense}
            ></EssenceAnimatedButton>
            <EssenceAnimatedButton
              name="delete"
              onClick={onDeleteExpense}
            ></EssenceAnimatedButton>
          </div>
        )}
      </Page>
    </AnimatedParent>
  );
}
