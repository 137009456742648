import { Link } from "react-router-dom";
import EssenceAnimatedRow from "../../../components/essence/row/animated-row.jsx";
import useLoaderDisplay from "../../../shared/hooks/loader-display.jsx";
// import WebWorker from "../../../web-worker/WebWorker.js";
// import appWorker from "../../../web-worker/app.worker.js";
import contactsManager from "../../services/contacts-manager.js";
import { useCircleQueries } from "../../../shared/tanstack/circle/queries.jsx";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import tokenService from "../../../shared/services/token-service.js";

export default function CircleList() {
  const accessToken = tokenService.getAccessToken();

  const { circlesQuery } = useCircleQueries({
    skipCirclesQuery: accessToken === undefined,
  });
  const { data, isLoading } = useQuery(circlesQuery);
  useLoaderDisplay({ key: "getCircles", isLoading });

  // contactsManager.syncContacts().then(_ => {
  //   debugger;
  //   contactsManager.getContacts().then(_ => {});

  //   //   console.log(`get contacts: ${JSON.stringify(contacts)}`);
  //   //   webWorker.postMessage({ type: "getContacts" });
  //   //   webWorker.addEventListener("message", (event) => {
  //   //     const sortedList = event.data;
  //   //     console.log(`Message from web workder: ${sortedList}`);
  //   //   });
  // });

  // const webWorker = new WebWorker(appWorker);

  useEffect(() => {
    // () => {
    //   return () => {
    //     console.log("Terminated");
    //     webWorker.terminate();
    //   };
    // };
    contactsManager.syncContacts().then((_) => {
      contactsManager.getContactsFromServer().then((_) => {});
    });
  }, []);

  return (
    <>
      {data?.map((circle) => {
        return (
          <Link key={circle.circleId} to={`/secure/circles/${circle.circleId}`}>
            <EssenceAnimatedRow
              title={circle.name}
              subTitle={`(${circle.interests?.join(", ")})`}
            />
          </Link>
        );
      })}
    </>
  );
}
