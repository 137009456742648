import styles from './loader.module.css'
export default function Loader() {
    return (
        <div className={styles['loader-bar']}>
            <span className={styles['loader-dot']}></span>
            <span className={styles['loader-dot']}></span>
            <span className={styles['loader-dot']}></span>
            <span className={styles['loader-dot']}></span>
            <span className={styles['loader-dot']}></span>
        </div>
    )
}