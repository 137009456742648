import Clickable from "../../../../components/essence/core/clickable";
import Animated from "../../../../components/essence/animated/animated";
import { useNavigate } from "react-router-dom";
import getMemberType from "../../member-type";
import { useParams } from "react-router-dom";
import { useCircleQueries } from "../../../../shared/tanstack/circle/queries";
import { useCircleMutations } from "../../../../shared/tanstack/circle/mutations";
import { useQuery, useMutation } from "@tanstack/react-query";

const Settings = () => {
  const navigate = useNavigate();
  const { circleId } = useParams();
  const { circleQuery } = useCircleQueries({ circleId });
  const { data: circle } = useQuery(circleQuery);
  const memberTypeData = getMemberType(circle?.type);
  const pluralTitle = memberTypeData?.pluralTitle ?? "members";
  const { deleteCircleMutation } = useCircleMutations({});
  const { mutate: deleteCircle } = useMutation(deleteCircleMutation);

  const goToAddparticipants = () => {
    if (Capacitor.isNativePlatform()) {
      navigate(`/secure/circles/${circleId}/participants`);
    } else {
      navigate(`/secure/circles/${circleId}/add-participant`);
    }
  };

  const goToModules = () => {
    navigate(`/secure/onboarding/circle-interests`, {
      state: { context: "Toggle_Module" },
    });
  };

  const handleRemoveCircle = () => {
    deleteCircle({ circleId });
    navigate(`/secure/circles`);
  };

  return (
    <>
      <Clickable key="participants">
        <Animated>
          <div className="headline-4 lowercase" onClick={goToAddparticipants}>
            {pluralTitle}
          </div>
        </Animated>
      </Clickable>
      {/* <Clickable key="modules">
        <Animated>
          <div className="headline-4 lowercase" onClick={goToModules}>
            modules
          </div>
        </Animated>
      </Clickable> */}
      <Clickable key="exit">
        <Animated>
          <div
            className="headline-4 lowercase"
            style={{ color: "red" }}
            onClick={handleRemoveCircle}
          >
            exit
          </div>
        </Animated>
      </Clickable>
    </>
  );
};

export default Settings;
