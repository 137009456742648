import { useQueryClient } from "@tanstack/react-query";
import { v4 as uuid } from "uuid";
import { toDoKeys } from "./keys";

export const useTodoMutations = ({ circleId }) => {
  const queryClient = useQueryClient();

  const addToDoMutation = {
    mutationKey: toDoKeys.add(),
    onMutate: async (newTodo) => {
      await queryClient.cancelQueries({ queryKey: toDoKeys.list(circleId) });
      const previousData = queryClient.getQueryData(toDoKeys.list(circleId));

      const clientToDoId = uuid();

      // Optimistically update to the new value
      queryClient.setQueryData(toDoKeys.list(circleId), (old) => {
        const pendingTodos = old.filter((todo) => todo.status === "PENDING");
        const nonPendingTodos = old.filter((todo) => todo.status !== "PENDING");
        return [
          ...pendingTodos,
          {
            ...newTodo,
            toDoId: clientToDoId,
            clientToDoId,
            status: "PENDING",
            dueDataTime: null,
            description: "",
            syncStatus: "PENDING_CREATE"
          },
          ...nonPendingTodos,
        ];
      });

      // Return a context object with the snapshotted value
      return { previousData, clientToDoId };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newToDo, context) => {
      queryClient.setQueryData(toDoKeys.list(circleId), context.previousData);
    },
    onSuccess: (data, variables, context) => {
      // Optimistically update to the new value
      queryClient.setQueryData(toDoKeys.list(variables.circleId), (todos) => {
        const updatedTodos = todos.map((todo) => {
          return todo.clientToDoId === context.clientToDoId
            ? {
                ...todo,
                toDoId: data.toDoId,
              }
            : todo;
        });

        return updatedTodos;
      });
    },
  };

  const toggleToDoMutation = {
    mutationKey: toDoKeys.toggle(),
    onMutate: async (newTodo) => {
      await queryClient.cancelQueries({ queryKey: toDoKeys.list(circleId) });
      const previousData = queryClient.getQueryData(toDoKeys.list(circleId));
      // Optimistically update to the new value
      queryClient.setQueryData(toDoKeys.list(circleId), (todos) => {
        const updatedTodos = todos?.map((todo) => {
          if (todo.toDoId === newTodo.toDoId) {
            const status =
              todo.status === "COMPLETED" ? "PENDING" : "COMPLETED";
            return {
              ...todo,
              status,
            };
          }
          return todo;
        });

        // Find the index of the updated todo
        const updatedTodoIndex = updatedTodos.findIndex(
          (todo) => todo.toDoId === newTodo.toDoId
        );

        // Remove the updated todo from the array
        const updatedTodo = updatedTodos.splice(updatedTodoIndex, 1)[0];

        // Push the updated todo to the end
        updatedTodos.push(updatedTodo);

        return updatedTodos;
      });

      return { previousData };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newToDo, context) => {
      queryClient.setQueryData(toDoKeys.list(circleId), context.previousData);
    },
  };

  const deleteToDoMutation = {
    mutationKey: toDoKeys.delete(),
    onMutate: async (newTodo) => {
      await queryClient.cancelQueries({ queryKey: toDoKeys.list(circleId) });
      const previousData = queryClient.getQueryData(toDoKeys.list(circleId));

      // Optimistically update to the new value
      queryClient.setQueryData(toDoKeys.list(circleId), (todos) => {
        const updatedTodos = todos?.map((todo) => {
          if (todo.toDoId === newTodo.toDoId) {
            return {
              ...todo,
              syncStatus: "PENDING_DELETE"
            };
          }
          return todo;
        });

        return updatedTodos;
      });

      // Return a context object with the snapshotted value
      return { previousData };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newToDo, context) => {
      queryClient.setQueryData(toDoKeys.list(circleId), context.previousData);
    }
  };

  const reorderToDoMutation = {
    mutationKey: toDoKeys.reorder(),
    onMutate: async (newTodo) => {
      await queryClient.cancelQueries({ queryKey: toDoKeys.list(circleId) });
      const previousTodos = queryClient.getQueryData(toDoKeys.list(circleId));

      const previousTodosData = newTodo.map((todo) => {
        const index = previousTodos.findIndex(
          (prevTodo) => prevTodo.toDoId === todo.toDoId
        );
        return previousTodos[index];
      });

      // Optimistically update to the new value
      queryClient.setQueryData(toDoKeys.list(circleId), previousTodosData);

      // Return a context object with the snapshotted value
      return { previousTodos };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(toDoKeys.list(circleId), context.previousData);
    },
  };

  return {
    addToDoMutation,
    toggleToDoMutation,
    deleteToDoMutation,
    reorderToDoMutation,
  };
};
