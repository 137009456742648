const getMemberType = (circleType) => {
    if (circleType?.toLowerCase() == 'family') {
        return { title: 'family member', pluralTitle: 'family members' }
    }
    if (circleType?.toLowerCase().indexOf('friend') === 0) {
        return { title: 'friend', pluralTitle: 'friends' }
    }
    if (circleType?.toLowerCase() == 'travel') {
        return { title: 'explorer', pluralTitle: 'explorers' }
    }
    if (circleType?.toLowerCase() == 'work') {
        return { title: 'colleague', pluralTitle: 'colleagues' }
    }
}

export default getMemberType;