import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import tokenService from "../../shared/services/token-service";
import styles from "./page.module.css";
import Header from "../../shared/components/header/page";
import Content from "../../shared/components/content/page";
import useLoaderDisplay from "../../shared/hooks/loader-display";
import { useCircleQueries } from "../../shared/tanstack/circle/queries";
import { useAccountMutations } from "../../shared/tanstack/account/mutations";
import { useMutation } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import { jwtDecode } from "jwt-decode";

export default function VerifyOtp() {
  const navigate = useNavigate();
  const [skipCirclesQuery, setSkipCirclesQuery] = useState(true);
  const referrerId = localStorage.getItem("referrerId");
  const fcmToken = tokenService.getFCMToken();
  const [searchParams] = useSearchParams();
  const [verificationCode, setVerificationCode] = useState("");

  const { verifyOtpMutation } = useAccountMutations();
  const {
    mutate: verifyOtp,
    data: token,
    isPaused: isSendingOtpPaused,
    status,
  } = useMutation(verifyOtpMutation);
  useLoaderDisplay({
    key: "verifyOtp",
    isLoading: status === "pending" && !isSendingOtpPaused,
  });
  const { circlesQuery } = useCircleQueries({ skipCirclesQuery });
  const { data: circles, isLoading: isCirclesLoading } = useQuery(circlesQuery);
  useLoaderDisplay({ key: "getCircles", isLoading: isCirclesLoading });

  useEffect(() => {
    const accessToken = tokenService.getAccessToken();
    const isProfileCreationPending = localStorage.getItem(
      "isProfileCreationPending"
    );
    if (accessToken) {
      if (isProfileCreationPending) {
        navigate("/secure/settings/profile", { replace: true });
      } else {
        navigate("/secure/circles", { replace: true });
      }
    }
  }, []);

  useEffect(() => {
    if (verificationCode.length === 6) {
      verifyCode();
    }
  }, [verificationCode]);

  useEffect(() => {
    if (token) {
      tokenService.setToken(token);
      const accessToken = tokenService.getAccessToken();
      const loggedInUserId = jwtDecode(accessToken).userId;
      FirebaseAnalytics.setUserId({
        userId: loggedInUserId,
      }).then((_) => {});

      if (token.name) {
        setSkipCirclesQuery(false);
      } else {
        localStorage.setItem("isProfileCreationPending", "true");
        navigate("/secure/settings/profile", {state: {
          phoneNumber: searchParams.get("phoneNumber"), 
          countryCode: searchParams.get("countryCode")
        }},{ replace: true });
      }
    }
  }, [token]);

  useEffect(() => {
    if (circles) {
      navigate(
        circles.length > 0
          ? "/secure/circles"
          : "/secure/onboarding/circle-info",
        {
          replace: true,
        }
      );
    }
  }, [circles]);

  const verifyCode = () => {
    // Call the API
    verifyOtp({
      phoneNumber: searchParams.get("phoneNumber").replace(/\D/g, ""),
      countryCode: searchParams.get("countryCode").replace(/\D/g, ""),
      otp: verificationCode,
      fcmToken,
      referrerId,
    });
  };

  const onVerificationCodeChange = ($event) => {
    var regex = new RegExp(/^[0-9]{0,6}$/, "g");
    if (regex.test($event.target.value)) {
      setVerificationCode($event.target.value);
    }
  };

  return (
    <>
      <Header
        title="Verify Code"
        subtitle={`enter the code sent to +${searchParams.get(
          "countryCode"
        )} ${searchParams.get("phoneNumber")}`}
      />
      <Content>
        <p className="text-2xl">Code</p>
        <div className="flex space-x-3">
          <input
            type="tel"
            className={styles["code-input"]}
            value={verificationCode}
            onChange={onVerificationCodeChange}
            essence-input="true"
            name="phone-number"
          />
        </div>
        <div>By verifying the OTP you agree to the <Link key="terms" to={`/terms`} style={{textDecoration: "underline"}}>terms & conditions</Link> & <Link key="privacy" style={{textDecoration: "underline"}} to={`/privacy`}>privacy policy</Link> </div>
      </Content>
    </>
  );
}
