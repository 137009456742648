import api from "../../services/api";

export const addCircle = async (body) => {
  return await api.post(`/api/circle`, body).then((res) => res.data);
};

export const deleteCircle = async ({ circleId }) => {
  return await api
    .delete(`/api/Circle/${circleId}`, {})
    .then((res) => res.data);
};

export const addParticipants = async ({ circleId, contacts }) => {
  const res = await api.put(`/api/circle/${circleId}/participant`, contacts);
  return res.data;
};

export const removeParticipants = async ({ circleId, participantId }) => {
  const res = await api.delete(
    `/api/circle/${circleId}/participant/${participantId}`
  );
  return res.data;
};
