import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import EssenceButton from "../../components/essence/button/button";
import tokenService from "../../shared/services/token-service";
import Header from "../../shared/components/header/page";
import Content from "../../shared/components/content/page";
import EssenceCountryCodePicker from "../../components/essence/country-code-picker/country-code-picker";
import useLoaderDisplay from "../../shared/hooks/loader-display";
import { useAccountMutations } from "../../shared/tanstack/account/mutations";
import { useMutation } from "@tanstack/react-query";

export default function Login() {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState({});
  const { sendOtpMutation } = useAccountMutations();
  const {
    mutate: sendLoginOtp,
    isSuccess: isSendOtpSuccess,
    isPaused,
    status,
  } = useMutation(sendOtpMutation);
  useLoaderDisplay({
    key: "sendOtp",
    isLoading: status === "pending" && !isPaused,
  });
  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    if (isSendOtpSuccess) {
      searchParams.set("phoneNumber", phoneNumber.replace(/\D/g, ""));
      searchParams.set("countryCode", countryCode.title.replace(/\D/g, ""));
      setSearchParams(searchParams);
      navigate(`/auth/verify-otp?${searchParams.toString()}`);
    }
  }, [isSendOtpSuccess]);

  const sendOTP = async (event) => {
    event.preventDefault();
    // Call the API
    sendLoginOtp({
      phoneNumber: phoneNumber.replace(/\D/g, ""),
      countryCode: countryCode.title.replace(/\D/g, ""),
    });
  };

  const onPhoneNumberChange = ($event) => {
    var regex = new RegExp(/^[0-9]{0,12}$/, "g");
    if (regex.test($event.target.value)) {
      setPhoneNumber($event.target.value);
    }
  };

  const onCountryCodeChange = (countryCode) => {
    setCountryCode(countryCode);
  };

  return (
    <>
      <Header
        title="Login"
        subtitle="set up your account with your phone number"
      />
      <Content>
        <form onSubmit={sendOTP}>
          <p className="headline-4">Phone Number</p>
          <div className="flex space-x-3">
            <EssenceCountryCodePicker
              onChange={onCountryCodeChange}
            ></EssenceCountryCodePicker>
            <input
              type="tel"
              value={phoneNumber}
              onChange={onPhoneNumberChange}
              essence-input="true"
              name="phone-number"
            />
          </div>
          <EssenceButton type="submit" name="send code" />
        </form>
      </Content>
    </>
  );
}
