import { useEffect, useState } from "react";
import styles from "./country-code-picker.module.css";
import EssencePickerList from "../core/picker-list/picker-list";
import EssencePickerOverlay from "../core/picker-overlay/picker-overlay";

("use client");
export default function CountryCodePickder({ onClose, onChange }) {
  const countryCodes = [
    { title: "+91", subtitle: "India" },
    // { title: "+1", subtitle: "USA" },
    { title: "+44", subtitle: "UK" },
    { title: "+49", subtitle: "Germany" },
    // { title: "+965", subtitle: "Kuwait" },
  ];

  const [internalCountryCode, setInternalCountryCode] = useState(
    countryCodes[0]
  );
  var [hidePicker, setHidePicker] = useState(true);

  const [countryCode, setCountryCode] = useState(countryCodes[0]);

  useEffect(() => {
    onChange(countryCode);
  }, [countryCode]);

  const onCountryCodeChange = ($event) => {
    setInternalCountryCode($event);
  };

  const accept = () => {
    setHidePicker(true);
    setCountryCode(internalCountryCode);
    onChange(internalCountryCode);
  };

  const reject = () => {
    setHidePicker(true);
    onClose();
  };

  const openPicker = (event) => {
    event?.preventDefault();
    setHidePicker(false);
  };

  return (
    <div>
      <div
        className={styles["country-code-picker-input"]}
        onClick={openPicker}
      >
        <input
          essence-input="true"
          value={countryCode.title}
          readOnly
          inputMode="none"
          className="w-full"
        />
      </div>
      {!hidePicker && (
        <EssencePickerOverlay
          id="overlay-date-picker"
          onAccept={accept}
          onReject={reject}
        >
          <div className={styles["picker-container"]}>
            <EssencePickerList
              id="date-picker-days-id"
              value={countryCode}
              onChange={onCountryCodeChange}
              className={styles["picker-list"]}
              options={countryCodes}
            ></EssencePickerList>
          </div>
        </EssencePickerOverlay>
      )}
    </div>
  );
}
