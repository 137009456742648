import Clickable from "../core/clickable";
import styles from "./row.module.css";

export default function EssenceRow(props) {
  return (
    <Clickable {...props}>
      <div className={`${styles["essence-row"]}`}>
        <div>
          <div className="headline-4">{props.title}</div>
          {props.subTitle && (
            <div
              className="caption"
              dangerouslySetInnerHTML={{ __html: props.subTitle }}
            ></div>
          )}
        </div>
        <div>
          {props.actionText && (
            <div className="headline-4 text-right">{props.actionText}</div>
          )}
        </div>
      </div>
    </Clickable>
  );
}
