export const toDoKeys = {
  all: (circleId) => ["todos", circleId],
  list: (circleId) => [...toDoKeys.all(circleId), "list"],
  toggle: () => ["todos", "toggle"],
  add: () => ["todos", "add"],
  delete: () => ["todos", "delete"],
  reorder: () => ["todos", "reorder"],
  details: (circleId) => [...toDoKeys.all(circleId), "detail"],
  detail: (toDoId, circleId) => [...toDoKeys.details(circleId), toDoId],
};
