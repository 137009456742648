import { useState, useEffect } from "react";
import Page from "../../../../components/essence/page/page.jsx";
import Animated from "../../../../components/essence/animated/animated.jsx";
import AnimatedParent from "../../../../components/essence/animated/animated-parent.jsx";
import getMemberType from "../../member-type.jsx";
import { useNavigate, useParams } from "react-router-dom";
import EssenceCheckbox from "../../../../components/essence/checkbox/checkbox.jsx";
import { useRef } from "react";
import EssenceButton from "../../../../components/essence/button/button.jsx";
import { useCircleQueries } from "../../../../shared/tanstack/circle/queries.jsx";
import { useCircleMutations } from "../../../../shared/tanstack/circle/mutations.jsx";
import { useQuery, useMutation } from "@tanstack/react-query";
import contactsManager from "../../../services/contacts-manager.js";
import styles from "./page.module.css";
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from "capacitor-native-settings";

const AddParticipants = () => {
  const navigate = useNavigate();
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [hasMoreContacts, setHasMoreContacts] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 50; // Adjust this to control the number of contacts fetched per page
  const observerRef = useRef(null);
  const { circleId } = useParams();
  const { circleQuery, participantsQuery } = useCircleQueries({ circleId });
  const { data: circle } = useQuery(circleQuery);
  const member = getMemberType(circle?.type)?.pluralTitle ?? "";
  const { data: participants } = useQuery(participantsQuery);
  const { addParticipantsMutation } = useCircleMutations({ circleId });
  const { mutate: addParticipant } = useMutation(addParticipantsMutation);

  useEffect(() => {
    if (participants && participants.length > 0) {
      contactsManager.getStoredContactsArray().then((allContacts) => {
        const filteredContacts = allContacts.filter(
          (contact) =>
            !participants.some(
              (participant) =>
                participant.phoneNumber === contact.phoneNumber &&
                participant.countryCode.toString() ===
                  contact.countryCode.toString()
            )
        );
        setContacts(filteredContacts);
      });
    }
  }, [participants]);

  useEffect(() => {
    const newContacts = contacts.slice(0, pageSize);
    setFilteredContacts([...newContacts]);
    setCurrentPage(currentPage + 1);
    setHasMoreContacts(newContacts.length === pageSize);
  }, [contacts]);

  useEffect(() => {
    if (observerRef.current) {
      const observer = new IntersectionObserver(handleObserver, {
        threshold: 0.1,
      });
      observer.observe(observerRef.current);
      return () => observer.disconnect();
    }
  }, [observerRef, filteredContacts]);

  const fetchData = async () => {
    try {
      const newContacts = await retrieveListOfContacts(currentPage);
      setFilteredContacts([...filteredContacts, ...newContacts]);
      setCurrentPage(currentPage + 1);
      setHasMoreContacts(newContacts.length === pageSize);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const retrieveListOfContacts = async (page) => {
    // let updatedContacts = await contactsManager.getStoredContactsArray();
    const filtered = contacts.filter(
      (contact) =>
        contact.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contact.phoneNumber?.includes(searchTerm)
    );
    const startIndex = page * pageSize;
    const endIndex = startIndex + pageSize;
    return filtered.slice(startIndex, endIndex);
  };

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && hasMoreContacts) {
      fetchData();
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0);
  };

  const handleToggleCompletion = (contactToUpdate) => {
    let updatedContacts = contacts.map((contact) => {
      if (
        contact.phoneNumber == contactToUpdate.phoneNumber &&
        contact.countryCode == contactToUpdate.countryCode
      ) {
        const isChecked = contact.isChecked;
        if (isChecked) {
          delete contact.isChecked;
          return contact;
        } else {
          const updatedContact = {
            ...contact,
            isChecked: !isChecked,
          };
          return updatedContact;
        }
      }
      return contact;
    });
    updatedContacts = updatedContacts.sort((x, y) =>
      x.name < y.name ? -1 : 0
    );
    updatedContacts = updatedContacts.sort((x, y) => (x.isChecked ? -1 : 0));
    setContacts(updatedContacts);
    setSearchTerm("");
  };

  useEffect(() => {
    if (searchTerm.length === 0) {
      setFilteredContacts(contacts.slice(0, pageSize));
    } else {
      const filtered = contacts.filter(
        (contact) =>
          contact.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          contact.phoneNumber?.includes(searchTerm)
      );
      setFilteredContacts(filtered.slice(0, pageSize));
    }
  }, [searchTerm]);

  const saveParticipants = () => {
    const participantsToAdd = contacts.filter((contact) => contact.isChecked);

    if (participantsToAdd.length > 0) {
      addParticipant({
        circleId,
        contacts: participantsToAdd.map((participant) => ({
          ...participant,
        })),
      });
    }

    navigate(-1);
  };

  const getName = (participant) => {
    const contact = contactsManager.getParticipant(participant);
    return contact.name?.length === 0 ? "-" : contact.name;
  };

  const getPhoneNumber = (participant) => {
    const contact = contactsManager.getParticipant(participant);
    const phoneNumber =
      contact.phoneNumber?.length === 0
        ? `xxx.xxx.${contact.lastFourNumber}`
        : contact.phoneNumber;
    return `+${contact.countryCode} ${phoneNumber}`;
  };

  const openSystemAppSettings = () => {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  };

  return (
    <AnimatedParent>
      <div style={{ position: "relative" }}>
        <Page title={`add ${member}`}>
          <div style={{ position: "relative" }}>
            <Animated>
              <input
                className={styles["mobile-search"]}
                essence-input="true"
                type="text"
                placeholder={"search " + member}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </Animated>

            <div className="pb-16">
              {(contacts.length === 0 || filteredContacts.length === 0) && (
                <div className="mt-10 body-1">
                  <div className="opacity-50">
                    We would like to access your contacts so that you can add them to the circle.
                    <br></br>
                    <br></br>
                    <div>
                      Please allow permission to access your contacts from the
                      system app settings.
                    </div>
                    <br></br>
                  </div>
                  <EssenceButton
                    name="open settings"
                    onClick={openSystemAppSettings}
                  />
                </div>
              )}

              {filteredContacts.map((contact, i) => (
                <EssenceCheckbox
                  key={`${i}${contact.phoneNumber}`}
                  title={getName(contact)}
                  subTitle={getPhoneNumber(contact)}
                  checked={contact.isChecked}
                  onChange={() => handleToggleCompletion(contact)}
                />
              ))}
              {hasMoreContacts && (
                <div ref={observerRef} className="text-center">
                  Loading...
                </div>
              )}
            </div>
          </div>
        </Page>
        {(contacts.length > 0 || filteredContacts.length > 0) && (
          <div className={`${styles["add-participants"]}`}>
            <EssenceButton name="add participants" onClick={saveParticipants} />
          </div>
        )}
      </div>
    </AnimatedParent>
  );
};

export default AddParticipants;
