"use client";
import { Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import styles from "./add-expense.module.css";
import { useCircleQueries } from "../../../../shared/tanstack/circle/queries";
import { useQuery } from "@tanstack/react-query";

export default function AddItinerary({ children }) {
  const { circleId } = useParams();
  const { circleQuery } = useCircleQueries({ circleId });
  const { data: circle } = useQuery(circleQuery);
  let url = window.location.href;
  let parts = url.split("/");
  let lastPart = parts[parts.length - 1];

  return (
    <div>
      <div className="grid grid-cols-12">
        <div className="hidden md:block col-span-6">
          <div className="hidden md:flex items-center justify-center flex-col h-screen">
            <h1 className="mt-10 text-5xl">{circle?.name}</h1>
            <h4 className="text-2xl mt-16 mb-10">
              Add itinerary in 2 easy steps
            </h4>
            <ol>
              <li
                className={`${styles.list} ${
                  lastPart === "expense-detail" ? styles["bold-style"] : ""
                }`}
              >
                Add Expense Details
              </li>
              <li
                className={`${styles.list} ${
                  lastPart === "payee" ? styles["bold-style"] : ""
                }`}
              >
                Enter Payee Details
              </li>
              <li
                className={`${styles.list} ${
                  lastPart === "participants" ? styles["bold-style"] : ""
                }`}
              >
                Select the Participants
              </li>
            </ol>
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 flex flex-col h-auto md:h-screen justify-center px-5 mt-4 md:px-16 md:bg-zinc-900">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
