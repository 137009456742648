import api from "../../services/api";

export const sendOtp = (body) =>
  api.post(`/api/account/sendLoginOtp`, body).then((res) => res.data);

export const verifyOtp = async (body) => {
  const queryParams = body.referrerId ? `referredBy=${body.referrerId}` : "";
  return await api
    .post(`/api/account/verifyOtp?${queryParams}`, body)
    .then((res) => res.data);
};

export const updateProfile = async (body) => {
  return await api.put(`/api/account/profile`, body).then((res) => res.data);
};

export const logout = async () => {
  return await api.post(`/api/logout`, {}).then((res) => res.data);
};

export const deleteAccount = async () => {
  return await api.delete(`/api/account`).then((res) => res.data);
};
