import api from "../../services/api";
import { toDoKeys } from "./keys";
import { getItems } from "../query-response-manager";
import { useQueryClient } from "@tanstack/react-query";

export const useTodoQueries = (circleId) => {
  const queryClient = useQueryClient();

  const toDoQuery = {
    queryKey: toDoKeys.list(circleId),
    queryFn: () =>
      api.get(`/api/todo?circleId=${circleId}`).then((res) => {
        const serverItems = res.data;
        const clientItems = queryClient.getQueryData(toDoKeys.list(circleId));
        const items = getItems(serverItems, clientItems, "toDoId");
        return items;
      }),
    refetchOnReconnect: false,
    select: (todos) =>
      todos.filter((todo) => todo.syncStatus !== "PENDING_DELETE"),
  };

  return {
    toDoQuery,
  };
};
