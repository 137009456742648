import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import Hub from "../../components/essence/hub/hub";
import EssenceAnimatedButton from "../../components/essence/button/animated-button";
import Todo from "./components/Todo/page.jsx";
import AnimatedParent from "../../components/essence/animated/animated-parent";
import Expenses from "./components/expenses/page";
import Settings from "./components/settings/page";
import { useCircleQueries } from "../../shared/tanstack/circle/queries";
import { useQuery } from "@tanstack/react-query";
import Itinerary from "./components/itinerary/page";
import { onlineManager } from "@tanstack/react-query";
import EssenceAlert from "../../components/essence/alert/alert.jsx";
import { useEffect, useState } from "react";
import swipeLeftAnimation from "./../../../scroll-left.json";
import styles from "../circles/page.module.css";
import coachMarkManager from "../services/coach-mark.js";
import storageKeyService from "../../shared/services/storage-key-service.js";

export default function CircleDetail() {
  const navigate = useNavigate();
  const [alert, setAlert] = useState({ show: false });
  const { circleId } = useParams();
  const { circleQuery } = useCircleQueries({ circleId });
  const { data: circle } = useQuery(circleQuery);
  const [displayAnimation, setDisplayAnimation] = useState(
    coachMarkManager.shouldShowCoachMark("circle-details")
  );
  const [searchParams] = useSearchParams();
  const storageKey = searchParams.get("token");

  useEffect(() => {
    storageKeyService.removeSettlementsExceptKey(storageKey);
  }, []);
  const hubItems = [
    ...(circle
      ? circle?.interests.map((x) => {
          return { title: x };
        })
      : [{ title: "todo" }, { title: "expenses" }]),
    { title: "settings" },
  ];

  useEffect(() => {
    if (!onlineManager.isOnline() && circle === undefined) {
      setAlert({
        show: true,
        message:
          "Please connect to the internet to get details of your circle.",
      });
    }
  }, []);

  const hideAlert = () => {
    setAlert({
      show: false,
    });
    navigate(-1);
  };

  const onSwipe = () => {
    coachMarkManager.hideCoachMark("circle-details");
    setDisplayAnimation(false);
  };

  useEffect(() => () => coachMarkManager.removeGestureListener(onSwipe), []);

  useEffect(() => {
    if (displayAnimation) {
      coachMarkManager.addGestureListener({
        listener: onSwipe,
        animation: {
          elementSelector: "#left-swipe-circle-detail",
          lottie: swipeLeftAnimation,
        },
      });
    }
  }, [displayAnimation]);

  return (
    <AnimatedParent>
      {alert.show && (
        <EssenceAlert
          onOk={hideAlert}
          okButton="okay"
          message={alert.message}
        ></EssenceAlert>
      )}
      <Hub title={circle?.name} hubItems={hubItems}>
        {hubItems.map((item) => (
          <div key={item.title} className="flex flex-col">
            <div className={styles.container}>
              {item.title?.toLowerCase() === "todo" && (
                <Todo circleId={circleId} circleName={circle?.name} />
              )}
              {displayAnimation && (
                <div
                  id="left-swipe-circle-detail"
                  className={styles["coach-mark"]}
                ></div>
              )}
            </div>
            {item.title?.toLowerCase().indexOf("expense") == 0 && (
              <Expenses circleId={circleId} />
            )}
            {item.title?.toLowerCase().indexOf("itinerary") == 0 && (
              <Itinerary circleId={circleId} />
            )}

            {item.title === "moments" && (
              <EssenceAnimatedButton name="Add Moment" />
            )}
            {item.title === "settings" && <Settings />}
          </div>
        ))}
      </Hub>
    </AnimatedParent>
  );
}
