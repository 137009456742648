"use client";
// import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import LeftPane from "../left-pane/left-pane";
import styles from "./page.module.css";

export default function Panel(props) {
  // const router = useRouter();
  useEffect(() => {
    if (
      localStorage.getItem("TOKEN") === null ||
      localStorage.getItem("TOKEN") === undefined
    ) {
      // router.replace('/auth/login');
    }
  }, []);
  return (
    <div>
      {/* <div className="flex gap-8 items-center md:hidden px-10 pt-10 pb-6">
        <Logo size={"40px"} />
        <h4 className="">
          /ˈsəːkl/ A circle is a group of people sharing common interests.
        </h4>
      </div> */}
      <div className="grid grid-cols-12">
        <div className="hidden md:block col-span-6">
          <LeftPane
            title={props.title ? props.title : "Let's create your first circle"}
            subtitle="/ˈsəːkl/ A circle is a group of people sharing common interests."
          />
        </div>
        <div  className={`${styles['outlet-container']} col-span-12 md:col-span-6 flex flex-col h-auto md:h-screen justify-center`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
