import Animated from "../animated/animated";
import EssenceButton from "./button";

export default function EssenceAnimatedButton(props) {
  return (
    <Animated>
      <EssenceButton {...props} />
    </Animated>
  );
}
