import { useState, useEffect } from "react";
import Page from "../../../../components/essence/page/page.jsx";
import Animated from "../../../../components/essence/animated/animated.jsx";
import AnimatedParent from "../../../../components/essence/animated/animated-parent.jsx";
import getMemberType from "../../member-type.jsx";
import { useNavigate, useParams } from "react-router-dom";
import EssenceCheckbox from "../../../../components/essence/checkbox/checkbox.jsx";
import { useRef } from "react";
import EssenceButton from "../../../../components/essence/button/button.jsx";
import { useCircleQueries } from "../../../../shared/tanstack/circle/queries.jsx";
import { useCircleMutations } from "../../../../shared/tanstack/circle/mutations.jsx";
import { useQuery, useMutation } from "@tanstack/react-query";
import contactsManager from "../../../services/contacts-manager.js";
import EssenceAlert from "../../../../components/essence/alert/alert.jsx";
import styles from "./page.module.css";
import tokenService from "../../../../shared/services/token-service.js";
import { jwtDecode } from "jwt-decode";

const AddParticipants = () => {
  const navigate = useNavigate();
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [hasMoreContacts, setHasMoreContacts] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 20; // Adjust this to control the number of contacts fetched per page
  const observerRef = useRef(null);
  const { circleId } = useParams();
  const { circleQuery, participantsQuery } = useCircleQueries({ circleId });
  const { data: circle } = useQuery(circleQuery);
  const member = getMemberType(circle?.type)?.pluralTitle ?? "";
  const { data: participants } = useQuery(participantsQuery);
  const { addParticipantsMutation, deleteParticipantsMutation } =
    useCircleMutations({ circleId });
  const {
    mutate: addParticipant,
    error: addParticipantError,
    reset: addParticipantReset,
  } = useMutation(addParticipantsMutation);

  const { mutate: removeParticipant } = useMutation(deleteParticipantsMutation);
  const { deleteCircleMutation } = useCircleMutations({});
  const { mutate: exitCircle } = useMutation(deleteCircleMutation);
  const [alert, setAlert] = useState({ show: false });
  const [warningAlert, setWarningAlert] = useState({ show: false });
  const [participantsMap, setParticipantsMap] = useState({});

  useEffect(() => {
    fetchData().then((_) => {
      contactsManager
        .getStoredContactsArray()
        .then((allContacts) => setContacts(allContacts));
    });

    // Fetch initial contacts when component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (participants) {
      for (let index = 0; index < participants.length; index++) {
        const participant = participants[index];
        participantsMap[participant.userId] = participant;
      }
      setParticipantsMap(participantsMap);
    }
  }, []);

  useEffect(() => {
    if (observerRef.current) {
      const observer = new IntersectionObserver(handleObserver, {
        threshold: 0.1,
      });
      observer.observe(observerRef.current);
      return () => observer.disconnect();
    }
  }, [observerRef, filteredContacts]);

  useEffect(() => {
    const error = addParticipantError ? addParticipantError : undefined;
    if (error) {
      setAlert({
        show: true,
        message: error.response.data.detail,
      });
    }
  }, [addParticipantError]);

  const fetchData = async () => {
    try {
      const newContacts = await retrieveListOfContacts(currentPage);
      setFilteredContacts(newContacts);
      setCurrentPage((prevPage) => prevPage + 1);
      setHasMoreContacts(newContacts.length === pageSize);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const retrieveListOfContacts = async (page) => {
    const updatedContacts = await contactsManager.getStoredContactsArray();
    return updatedContacts.map(contact => contactsManager.getParticipant(contact))
    // return updatedContacts;
    // const startIndex = page * pageSize;
    // const endIndex = startIndex + pageSize;
    // return updatedContacts.slice(startIndex, endIndex);
  };

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && hasMoreContacts) {
      fetchData();
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0);
  };

  const handleToggleCompletion = (contactToUpdate) => {
    const updatedContacts = filteredContacts.map((contact) => {
      if (contact.userId == contactToUpdate.userId) {
        const isChecked = _isParticipant(contactToUpdate);
        const updatedContact = {
          ...contact,
          checked: !isChecked,
        };
        if (updatedContact.checked) {
          participantsMap[contactToUpdate.userId] = {
            ...contactToUpdate,
            status: "PENDING_CREATE",
          };
        } else {
          const accessToken = tokenService.getAccessToken();
          const loggedInUserId = jwtDecode(accessToken).userId;

          if (contact.userId === loggedInUserId) {
            setWarningAlert({
              show: true,
              message: "Are you sure you want to exit the circle?",
              context: {
                contact: updatedContact,
              },
            });
          } else {
            participantsMap[contactToUpdate.userId] = {
              ...participantsMap[contactToUpdate.userId],
              status: "PENDING_DELETE",
            };
          }
        }
        return updatedContact;
      }
      return contact;
    });
    setParticipantsMap(participantsMap);
    setFilteredContacts(updatedContacts);
    setSearchTerm("");
  };

  const _isParticipant = (contact) => {
    return (
      participantsMap.hasOwnProperty(contact.userId) &&
      participantsMap[contact.userId].status !== "PENDING_DELETE"
    );
  };

  const hideAlert = () => {
    addParticipantReset();
    setAlert({
      show: false,
    });
    setWarningAlert({
      show: false,
    });
  };

  useEffect(() => {
    if (searchTerm.length === 0) {
      setFilteredContacts(contacts);
    } else {
      const filtered = contacts.filter(
        (contact) =>
          contact.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          contact.phoneNumber?.includes(searchTerm)
      );
      setFilteredContacts(filtered.slice(0, 20));
    }
  }, [searchTerm]);

  const saveParticipants = () => {
    const allParticipanstFromMap = Object.values(participantsMap);
    const participantsToAdd = allParticipanstFromMap.filter(
      (participant) => participant.status === "PENDING_CREATE"
    );

    if (participantsToAdd.length > 0) {
      addParticipant({
        circleId,
        contacts: participantsToAdd.map((participant) => ({
          ...participant,
        })),
      });
    }

    const participantsToRemove = allParticipanstFromMap.filter(
      (participant) => participant.status === "PENDING_DELETE"
    );

    if (participantsToRemove.length > 0) {
      removeParticipant({
        circleId,
        contacts: participantsToRemove.map((participant) => ({
          ...participant,
        })),
      });
    }

    navigate(-1);
  };

  const getName = (participant) => {
    return participant.name?.length === 0 ? "-" : participant.name;
  };

  const getPhoneNumber = (participant) => {
    const phoneNumber =
      participant.phoneNumber && participant.phoneNumber.length > 0
        ? participant.phoneNumber
        : `xxx.xxx.${participant.lastFourNumber}`;
    return `+${participant.countryCode} ${phoneNumber}`;
  };

  const _exitCircle = () => {
    exitCircle({ circleId });
    setWarningAlert({ show: false });
    navigate(-2);
  };

  return (
    <AnimatedParent>
      {alert.show && (
        <EssenceAlert
          onOk={hideAlert}
          okButton="okay"
          message={alert.message}
        ></EssenceAlert>
      )}

      {warningAlert.show && (
        <EssenceAlert
          onOk={_exitCircle}
          okButton="exit"
          onDismiss={hideAlert}
          dismissButton={"cancel"}
          message={"Are you sure you want to exit the circle?"}
        ></EssenceAlert>
      )}
      <Page title={member}>
        <div className="md:grid-cols-2 grid grid-cols-1">
          <div className="inherit">
            {contacts.length > 0 && (
              <Animated>
                <input
                  className={styles.search}
                  essence-input="true"
                  type="text"
                  placeholder={"search " + member}
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </Animated>
            )}
            <div>
              {contacts.length === 0 && (
                <div className="mt-10 body-1 opacity-50">
                  Please sync contacts from your mobile.<br></br> Only the
                  contacts who've signed up on Circles will be visible here.{" "}
                </div>
              )}
              {filteredContacts.map((contact, i) => (
                <EssenceCheckbox
                  key={i}
                  title={getName(contact)}
                  subTitle={getPhoneNumber(contact)}
                  checked={_isParticipant(contact)}
                  onChange={() => handleToggleCompletion(contact)}
                />
              ))}
              {hasMoreContacts && (
                <div ref={observerRef} className="text-center">
                  Loading...
                </div>
              )}
            </div>
          </div>
          <div>
            <Animated>
              <div className="headline-4">{member}</div>
            </Animated>

            {Object.values(participantsMap)?.map((contact) => {
              if (contact.status !== "PENDING_DELETE") {
                return (
                  <EssenceCheckbox
                    title={getName(contact)}
                    subTitle={getPhoneNumber(contact)}
                    checked={true}
                    onChange={() => handleToggleCompletion(contact)}
                  />
                );
              }
              return <></>;
            })}

            <div className={`${styles["add-participants"]} mt-10`}>
              <EssenceButton
                name="save participants"
                onClick={saveParticipants}
              />
            </div>
          </div>
        </div>
      </Page>
    </AnimatedParent>
  );
};

export default AddParticipants;
