import { useEffect, useRef, useState } from "react";
import Animated from "../animated/animated";
import Page from "../page/page";
import styles from "./hub.module.css";
import { useSearchParams } from "react-router-dom";

export default function Hub({ title, hubItems, children }) {
  const hubContainer = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentIndex, setCurrentIndex] = useState(
    searchParams.get("index") ?? 0
  );

  useEffect(() => {
    scrollToIndex(currentIndex, "auto");
  }, [currentIndex]);

  const onScroll = ($event) => {
    // TODO: For webview the index will always remain in float.
    // Calculate the index correctly for web/desktop version
    const index =
      (hubContainer.current.scrollLeft / (hubItems.length * screen.width)) *
      hubItems.length;
    if (Number.isInteger(index) && index !== currentIndex) {
      setCurrentIndex(index);
      // setSearchParams({ index });
    }
  };

  const onTitleClick = (i) => {
    scrollToIndex(i, "smooth");
  };

  const scrollToIndex = (i, behavior) => {
    hubContainer.current.scrollTo({
      left: (hubContainer.current.scrollWidth / hubItems.length) * i,
      behavior,
    });
  };

  return (
    <div ref={hubContainer} className={styles.parallax} onScroll={onScroll}>
      <div className={`${styles.title}`}>
        <Animated>{title}</Animated>
      </div>

      <div className={styles.container}>
        {hubItems.map((hubItem, i) => {
          return (
            <Page
              key={hubItem.title}
              title={hubItem.title}
              children={children[i]}
              onTitleClick={() => onTitleClick(i)}
            />
          );
        })}
      </div>
      <div className={styles["dots-container"]}>
        {hubItems.map((hubItem, i) => {
          return (
            <div key={i} onClick={() => onTitleClick(i)}>
              <div
                className={`${styles.dots} ${
                  i === currentIndex ? styles.active : ""
                }`}
              ></div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
