import { useQueryClient } from "@tanstack/react-query";
import { accountKeys, fcmTokenKeys } from "./keys";
import { sendOtp, verifyOtp } from "./api";

export const useAccountMutations = () => {
  const queryClient = useQueryClient();

  const sendOtpMutation = {
    mutationFn: sendOtp,
  };

  const verifyOtpMutation = {
    mutationFn: verifyOtp,
    onMutate: (variables) => {
      const oldAccount = queryClient.getQueryData({
        queryKey: accountKeys.all(),
      });
      queryClient.setQueryData(accountKeys.all(), (oldAccount) => ({
        ...oldAccount,
        ...variables,
      }));
      return { oldAccount };
    },
  };

  const updateProfileMutation = {
    mutationKey: accountKeys.update(),
    onMutate: (variables) => {
      const oldAccount = queryClient.getQueryData({
        queryKey: accountKeys.all(),
      });
      queryClient.setQueryData(accountKeys.all(), (oldAccount) => ({
        ...oldAccount,
        ...variables,
      }));
      return { oldAccount };
    },
  };

  const deleteAccountMutation = {
    mutationKey: accountKeys.delete(),
    onMutate: (variables) => {
      const oldAccount = queryClient.getQueryData({
        queryKey: accountKeys.all(),
      });
      const oldFCMTokens = queryClient.getQueryData({
        queryKey: fcmTokenKeys.list(),
      });
      queryClient.setQueryData(accountKeys.all(), undefined);
      queryClient.setQueryData(fcmTokenKeys.list(), undefined);
      return { oldAccount, oldFCMTokens };
    },
    onError: (_err, _account, context) => {
      queryClient.setQueryData(accountKeys.all(), context.oldAccount);
      queryClient.setQueryData(fcmTokenKeys.list(), context.oldFCMTokens);
    },
  };

  const logoutMutation = {
    mutationKey: accountKeys.logout(),
    onMutate: async (_) => {
      const oldAccount = queryClient.getQueryData({
        queryKey: accountKeys.all(),
      });
      queryClient.setQueryData(accountKeys.all(), undefined);
      queryClient.setQueryData(fcmTokenKeys.list(), undefined);
      return { oldAccount };
    },
  };

  return {
    deleteAccountMutation,
    updateProfileMutation,
    logoutMutation,
    sendOtpMutation,
    verifyOtpMutation,
  };
};
