export const transformScale = (element, scale) => {
  if (element.style) {
    if (element.style.transform?.indexOf("scale(") < 0) {
      element.style.transform = element.style.transform += ` scale(${scale} `;
    } else {
      element.style.transform = element.style.transform.replace(
        /scale\([0-9|\.]*\)/,
        ` scale(${scale}) `
      );
    }
  }
};

export const transformRotation = (element, x, y) => {
  if (element.style) {
    if (element.style.transform?.indexOf("rotateX(") < 0) {
      element.style.transform += ` rotateX(${x}deg) rotateY(${y}deg) `;
    } else {
      element.style.transform = element.style.transform.replace(
        /rotateX\(.*deg\) rotateY\(.*deg\)/,
        `rotateX(${x}deg) rotateY(${y}deg) `
      );
    }
  }
};
