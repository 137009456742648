import { addTodo, deleteTodo, toggleTodo, reorderTodo } from "./api.jsx";
import { toDoKeys } from "./keys.jsx";

export const setDefaultTodosMutation = (queryClient) => {
  
  // we need a default mutation function so that paused mutations can resume after a page reload
  queryClient.setMutationDefaults(toDoKeys.add(), {
    mutationFn: async (todo) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({
        queryKey: toDoKeys.add(),
      });

      return await addTodo(todo);
    },
  });

  // we need a default mutation function so that paused mutations can resume after a page reload
  queryClient.setMutationDefaults(toDoKeys.toggle(), {
    mutationFn: async (todo) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({
        queryKey: toDoKeys.toggle(),
      });
      const todoToUpdate = _getCachedTodo(todo);
      return await toggleTodo(todoToUpdate);
    },
  });

  // we need a default mutation function so that paused mutations can resume after a page reload
  queryClient.setMutationDefaults(toDoKeys.delete(), {
    mutationFn: async (todo) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({
        queryKey: toDoKeys.delete(),
      });
      const todoToUpdate = _getCachedTodo(todo);
      return await deleteTodo(todoToUpdate);
    },
  });

  // we need a default mutation function so that paused mutations can resume after a page reload
  queryClient.setMutationDefaults(toDoKeys.reorder(), {
    mutationFn: async (todos) => {
      // to avoid clashes with our optimistic update when an offline mutation continues
      await queryClient.cancelQueries({
        queryKey: toDoKeys.reorder(),
      });

      const todosToUpdate = todos.map((todo) => _getCachedTodo(todo));
      return await reorderTodo(todosToUpdate);
    },
  });

  const _getCachedTodo = (todo) => {
    const todos = queryClient.getQueryData(toDoKeys.list(todo.circleId));
    const updatedTodo = todos?.filter((x) => x.clientToDoId === todo.toDoId);
    return updatedTodo?.length > 0 ? updatedTodo[0] : todo;
  };
};
