import Animated from "../animated/animated";
import EssenceRow from "./row";

export default function EssenceAnimatedRow(props) {
  return (
    <Animated>
      <EssenceRow {...props} />
    </Animated>
  );
}
