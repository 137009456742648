import api from "../../services/api";

export const toggleTodo = async ({ toDoId }) => {
  return await api
    .put(`/api/todo/${toDoId}/ToggleStatus`, {})
    .then((res) => res.data);
};

export const addTodo = async (todo) => {
  const res = await api.post(`/api/todo`, todo);
  return res.data;
};

export const deleteTodo = async ({ toDoId }) => {
  const res = await api.delete(`/api/todo`, { data: [toDoId] });
  return res.data;
};

export const reorderTodo = async (todo) => {
  return await api.put(`/api/todo/reorder`, todo).then((res) => res.data);
};
