import api from "../../services/api";

export const addExpense = async (body) => {
  return await api.post(`/api/expense`, body).then((res) => res.data);
};

export const deleteExpense = async ({ expenseId }) => {
  return await api.delete(`/api/expense/${expenseId}`).then((res) => res.data);
};

export const updateExpense = async (body) => {
  return await api
    .put(`/api/expense/${body.expenseId}`, body)
    .then((res) => res.data);
};
