import { useEffect, useState } from "react";
import EssencePickerOption from "../picker-option/picker-option";
import styles from "./picker-list.module.css";

export default function EssencePickerList({ options, onChange, value, id }) {
  const optionHeight = parseInt(
    window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--picker-option-size")
  );
  var [state, setState] = useState({
    isScrolling: false,
    activeIndex: value?.index ?? 0,
    isHovering: false,
  });

  useEffect(() => {
    scrollToIndex(state.activeIndex ?? 0);
  }, []);

  const scrollToIndex = (index) => {
    const scroller = document.getElementById(id);
    const element = scroller.children[index];
    if (element && scroller) {
      scroller.scrollTo({top: index * optionHeight, behavior: "smooth"})
      // scroller.scrollTop = index * optionHeight;
    }
  };

  var onScroll = () => {
    setState({
      isScrolling: true,
      isHovering: state.isHovering,
      activeIndex: state.activeIndex,
    });

    const scroller = document.getElementById(id);
    const approxIndex = scroller.scrollTop / optionHeight;
    const roundedNumber = Math.round(approxIndex);
    const difference = Math.abs(
      roundedNumber - scroller.scrollTop / optionHeight
    );
    if (scroller && (Number.isInteger(approxIndex) || difference < 0.003)) {
      onScrollEnd(state.isHovering);
    }
  };

  const onScrollEnd = (isHovering) => {
    const scroller = document.getElementById(id);
    const index = Math.round(scroller.scrollTop / optionHeight);

    onChange(options[index]);

    setState({
      isScrolling: false,
      isHovering: false,
      activeIndex: index,
    });
  };

  const selectOption = (option) => {
    scrollToIndex(option.index);
  };

  return (
    <ul
      className={styles.scroller}
      id={id}
      onScroll={onScroll}
      onMouseOver={() => {
        setState({
          isScrolling: state.isScrolling,
          isHovering: true,
          activeIndex: state.activeIndex,
        });
      }}
      onMouseLeave={() => {
        setState({
          isScrolling: state.isScrolling,
          isHovering: false,
          activeIndex: state.activeIndex,
        });
      }}
    >
      {options.map((option, i) => (
        <li className={styles["list-item"]}>
          <EssencePickerOption
            isHovering={state.isHovering}
            isScrolling={state.isScrolling}
            isActive={i === state.activeIndex}
            option={{ ...option, index: i }}
            onClick={selectOption}
          ></EssencePickerOption>
        </li>
      ))}
    </ul>
  );
}
