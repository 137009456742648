import api from "../../services/api";

export const addItinerary = async (body) => {
  return await api.post(`/api/itinerary`, body).then((res) => res.data);
};

export const deleteItinerary = async (body) => {
  return await api.delete(`/api/itinerary/${body.itineraryId}/step/${body.stepId}`).then((res) => res.data);
};

export const updateItinerary = async (body) => {
  return await api
    .put(`/api/itinerary/${body.itineraryId}/step/${body.stepId}`, body)
    .then((res) => res.data);
};
