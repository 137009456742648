import React, { useState } from "react";
import EssenceAnimatedButton from "../../../../components/essence/button/animated-button";
import Header from "../../../../shared/components/header/page";
import Content from "../../../../shared/components/content/page";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import EssenceDatePicker from "../../../../components/essence/date-picker/date-picker";
import { useExpenseMutations } from "../../../../shared/tanstack/expenses/mutations";
import { useMutation } from "@tanstack/react-query";

const AddSettlementDetail = () => {
  const navigate = useNavigate();
  const { circleId } = useParams();
  const location = useLocation();
  const { settlementDetail: stateSettlementDetail } = location.state;
  const [settlementDetail, setSettlementDetail] = useState(
    stateSettlementDetail
  );

  const { addExpenseMutation, updateExpenseMutation } = useExpenseMutations({
    circleId,
  });

  const { mutate: addExpense } = useMutation(addExpenseMutation);
  const { mutate: updateExpense } = useMutation(updateExpenseMutation);

  const onAmountChange = ($event) => {
    var regex = new RegExp(/^[0-9]*\.?[0-9]{0,2}?$/);
    let amount = $event.target.value;

    if (regex.test(amount)) {
      setSettlementDetail({
        ...settlementDetail,
        amount: amount,
      });
    }
  };

  const onBlur = () => {
    if (settlementDetail.amount) {
      setSettlementDetail({
        ...settlementDetail,
        amount: parseFloat(settlementDetail.amount),
      });
    }
  };

  const settleAmount = (e) => {
    e.preventDefault();
    const debtors = [
      {
        userId: settlementDetail.creditorId,
        amount: settlementDetail.amount,
        curency: settlementDetail.currency,
      },
    ];
    const creditors = [
      {
        userId: settlementDetail.debtorId,
        amount: settlementDetail.amount,
        curency: settlementDetail.currency,
      },
    ];

    const settlment = {
      ...settlementDetail,
      creditors: creditors,
      debtors: debtors,
    };
    if (settlementDetail.expenseId) {
      updateExpense(settlment);
      navigate(-3);
    } else {
      addExpense(settlment);
      navigate(-2);
    }
  };

  return (
    <>
      <Header
        title="Details"
        subtitle={`Enter the details of the settlement.`}
      />
      <Content>
        <div>
          <div className="headline-5">Amount</div>
          <input
            onBlur={onBlur}
            essence-input="true"
            placeholder="Amount"
            value={settlementDetail.amount}
            onChange={onAmountChange}
            name="settlement-amount"
            type="tel"
          />
        </div>
        <div className="mt-3 mb-6">
          <div className="headline-5">Date</div>
          <EssenceDatePicker
            date={settlementDetail.dateTime}
            onChange={(event) =>
              setSettlementDetail({
                ...settlementDetail,
                dateTime: event.target.value.toISOString(),
              })
            }
          ></EssenceDatePicker>
        </div>
        <EssenceAnimatedButton onClick={settleAmount} name="settle up" />
      </Content>
    </>
  );
};

export default AddSettlementDetail;
