import { useQueryClient } from "@tanstack/react-query";
import { v4 as uuid } from "uuid";
import { circlesKeys } from "./keys";

export const useCircleMutations = ({ circleId }) => {
  const queryClient = useQueryClient();

  const addCircleMutation = {
    mutationKey: circlesKeys.add(),
    onMutate: async (newCircle) => {
      await queryClient.cancelQueries({ queryKey: circlesKeys.list() });
      // Snapshot the previous value
      const previousCircles = queryClient.getQueryData(circlesKeys.list());

      const clientCircleId = uuid();
      const oldCircles = previousCircles ? previousCircles : [];
      // Optimistically update to the new value
      queryClient.setQueryData(circlesKeys.list(), (old) => [
        ...oldCircles,
        {
          ...newCircle,
          circleId: clientCircleId,
          clientCircleId,
          syncStatus: "PENDING_CREATE",
          createdAt: new Date(),
          updatedAt: new Date(),
        },
      ]);

      // Return a context object with the snapshotted value
      return { previousCircles, clientCircleId };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (_err, _newCircle, context) => {
      queryClient.setQueryData(circlesKeys.list(), context.previousCircles);
    },
    onSuccess: (data, variables, context) => {
      // Update the id to the one returned from server
      queryClient.setQueryData(circlesKeys.list(), (circles) => {
        const updatedCircles = circles.map((circle) => {
          return circle.clientCircleId === context.clientCircleId
            ? {
                ...circle,
                circleId: data.circleId,
              }
            : circle;
        });
        return updatedCircles;
      });
    },
  };

  const deleteCircleMutation = {
    mutationKey: circlesKeys.delete(),
    onMutate: async (newCircle) => {
      await queryClient.cancelQueries({ queryKey: circlesKeys.list() });
      // Snapshot the previous value
      const previousCircles = queryClient.getQueryData(circlesKeys.list());

      // Optimistically update to the new value
      queryClient.setQueryData(circlesKeys.list(), (circles) =>
        circles.map((circle) => {
          if (circle.circleId === newCircle.circleId) {
            return {
              ...circle,
              deletedAt: new Date(),
              syncStatus: "PENDING_DELETE",
            };
          } else {
            return circle;
          }
        })
      );

      // Return a context object with the snapshotted value
      return { previousCircles };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (_err, _newExpense, context) => {
      queryClient.setQueryData(circlesKeys.list(), context.previousCircles);
    },
  };

  const addParticipantsMutation = {
    mutationKey: circlesKeys.updateParticipants(),
    onMutate: async ({ contacts, circleId }) => {
      await queryClient.cancelQueries({
        queryKey: circlesKeys.participants(circleId),
      });
      // Snapshot the previous value
      const previousParticipants = queryClient.getQueryData(
        circlesKeys.participants(circleId)
      );

      const updateParticipants = contacts.map((participant) => ({
        ...participant,
        updatedAt: new Date(),
        syncStatus: "PENDING_CREATE",
      }));

      // Optimistically update to the new value
      queryClient.setQueryData(circlesKeys.participants(circleId), (old) => {
        return [...old, ...updateParticipants];
      });

      // Return a context object with the snapshotted value
      return { previousParticipants };
    },
  };

  const deleteParticipantsMutation = {
    mutationKey: circlesKeys.removeParticipants(),
    onMutate: async ({ circleId, contacts }) => {
      await queryClient.cancelQueries({
        queryKey: circlesKeys.participants(circleId),
      });
      // Snapshot the previous value
      const previousParticipants = queryClient.getQueryData(
        circlesKeys.participants(circleId)
      );

      // Optimistically update to the new value
      // Optimistically update to the new value
      queryClient.setQueryData(
        circlesKeys.participants(circleId),
        (participants) =>
          participants.map((participant) => {
            if (
              contacts.some((contact) => contact.userId === participant.userId)
            ) {
              return {
                ...participant,
                deletedAt: new Date(),
                syncStatus: "PENDING_DELETE",
              };
            } else {
              return participant;
            }
          })
      );

      // Return a context object with the snapshotted value
      return { previousParticipants };
    },
  };

  return {
    addCircleMutation,
    deleteCircleMutation,
    addParticipantsMutation,
    deleteParticipantsMutation,
  };
};
