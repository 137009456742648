class TokenService {
  getRefreshToken() {
    const tokenStr = localStorage.getItem("TOKEN");
    if (tokenStr) {
      return JSON.parse(tokenStr).refreshToken;
    }
    return undefined;
  }

  getAccessToken() {
    const tokenStr = localStorage.getItem("TOKEN");
    if (tokenStr) {
      return JSON.parse(tokenStr).token;
    }
    return undefined;
  }

  setToken(token) {
    localStorage.setItem("TOKEN", JSON.stringify(token));
  }

  removeToken() {
    localStorage.removeItem("TOKEN");
  }

  getFCMToken() {
    return localStorage.getItem("FCM_TOKEN");
  }

  setFCMToken(token) {
    localStorage.setItem("FCM_TOKEN", token);
  }
}

export default new TokenService();
