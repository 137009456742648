import EssenceAnimatedButton from "../../../../components/essence/button/animated-button";
import EssenceAnimatedCheckbox from "../../../../components/essence/checkbox/animated-checkbox";
import Header from "../../../../shared/components/header/page";
import Content from "../../../../shared/components/content/page";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import styles from "./add-expense.module.css";
import { useState, useEffect } from "react";
import getMemberType from "../../member-type";
import { useCircleQueries } from "../../../../shared/tanstack/circle/queries";
import { useExpenseMutations } from "../../../../shared/tanstack/expenses/mutations";
import { useQuery, useMutation } from "@tanstack/react-query";
import tokenService from "../../../../shared/services/token-service";
import { jwtDecode } from "jwt-decode";

const Participants = () => {
  const { circleId } = useParams();
  const accessToken = tokenService.getAccessToken();
  const loggedInUserId = jwtDecode(accessToken).userId;
  const { circleQuery, participantsQuery } = useCircleQueries({ circleId });
  const { data: circle } = useQuery(circleQuery);
  const location = useLocation();
  const { data: circleParticipants } = useQuery(participantsQuery);
  const [participants, setParticipants] = useState(circleParticipants);
  const { expenseDetail } = location.state;
  const { addExpenseMutation, updateExpenseMutation } = useExpenseMutations({
    circleId,
  });
  const { mutate: addExpense, isError: isAddExpenseError } =
    useMutation(addExpenseMutation);
  const { mutate: updateExpense, isError: isUpdateExpenseError } = useMutation(
    updateExpenseMutation
  );
  const [isDirty, setIsDirty] = useState(false);

  const navigate = useNavigate();
  const member = getMemberType(circle?.type)?.pluralTitle ?? "";

  const creditAmount = participants?.reduce(
    (sum, participant) => sum + (parseFloat(participant.amount) || 0),
    0
  );

  useEffect(() => {
    if (isAddExpenseError || isUpdateExpenseError) {
      alert("Some unknown error occured");
    }
  }, [isAddExpenseError, isUpdateExpenseError]);
  useEffect(() => {
    if (circleParticipants && circleParticipants.length > 0) {
      var updatedParticipants = circleParticipants.map((participant) => {
        const amountToPay = parseFloat(
          (expenseDetail.amount / circleParticipants.length).toFixed(2)
        );
        const isChecked = true;
        return {
          ...participant,
          isChecked,
          amount: amountToPay,
        };
      });
      const randomIndex = Math.floor(
        Math.random() * circleParticipants?.length
      );

      const creditAmount = updatedParticipants?.reduce(
        (sum, participant) => sum + (participant.amount || 0),
        0
      );
      const remainingAmount = parseFloat(
        (expenseDetail.amount - creditAmount).toFixed(2)
      );
      if (remainingAmount != 0) {
        updatedParticipants = updatedParticipants.map((participant, index) => {
          if (index == randomIndex) {
            return {
              ...participant,
              isChecked: true,
              amount: parseFloat(
                ((participant?.amount ?? 0) + remainingAmount).toFixed(2)
              ),
            };
          } else {
            return participant;
          }
        });
      }
      setParticipants(updatedParticipants);
    }
  }, [circleParticipants]);

  const onSaveExpense = (event) => {
    const debtors = participants?.filter(
      (participant) => participant.amount > 0
    );
    event.preventDefault();
    if (!expenseDetail.expenseId) {
      addExpense({
        ...expenseDetail,
        circleId: circleId,
        debtors: debtors,
      });
      navigate(-3);
    } else {
      updateExpense({
        ...expenseDetail,
        circleId: circleId,
        debtors: debtors,
        expenseId: expenseDetail.expenseId,
      });
      navigate(-4);
    }
  };

  const onToggle = (userId) => {
    let updatedParticipants = participants?.map((participant) => {
      if (participant.userId === userId) {
        return { ...participant, isChecked: !participant.isChecked, amount: 0 };
      }
      return participant;
    });

    if(!isDirty){
      const checkedUsers = updatedParticipants.filter((participant) => participant.isChecked);
      const amountToDistribute = parseFloat((expenseDetail.amount / checkedUsers.length).toFixed(2));
      updatedParticipants = updatedParticipants.map((participant) => ({
        ...participant,
        amount: participant.isChecked ? amountToDistribute : 0,
      }));
      
      if(checkedUsers.length === participants.length){
        const debitAmount = updatedParticipants?.reduce(
          (sum, participant) => sum + (parseFloat(participant.amount) || 0),
          0
        );
        const remainingAmount = expenseDetail.amount - debitAmount;
        if(remainingAmount !== 0){
          const randomIndex = Math.floor(
            Math.random() * circleParticipants?.length
          );
          updatedParticipants = updatedParticipants.map((participant, index) => {
            if (index === randomIndex) {
              return {
                ...participant,
                amount: (amountToDistribute + remainingAmount).toFixed(2),
              };
            } else {
              return participant;
            }
          })
        }
      }
    }
    setParticipants(updatedParticipants);
  };

  const onAmountChange = ($event, userId) => {
    setIsDirty(true);
    var regex = new RegExp(/^[0-9]*\.?[0-9]{0,2}?$/);
    let amount = $event.target.value;

    if (regex.test(amount)) {
      // amount = parseFloat(amount);
      const updatedParticipants = participants?.map((participant) => {
        if (participant.userId === userId) {
          return { ...participant, amount };
        }
        return participant;
      });
      setParticipants(updatedParticipants);
    }
  };

  const onBlur = () => {
    const updatedParticipants = participants?.map((participant) => {
      if (participant.amount) {
        return { ...participant, amount: parseFloat(participant.amount) };
      }
      return participant;
    });
    setParticipants(updatedParticipants);
  };

  const form = {
    get isDisabled() {
      return parseFloat((expenseDetail.amount - creditAmount).toFixed(2)) != 0
        ? true
        : undefined;
    },
  };
  return (
    <>
      <Header
        title={`${member}`}
        subtitle={`Select the list of ${member} who are a part of the expense.`}
      />
      <Content>
        <ul>
          {participants?.map((participant) => (
            <li
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <EssenceAnimatedCheckbox
                title={
                  participant.userId === loggedInUserId
                    ? "You"
                    : participant.name
                }
                checked={participant.isChecked}
                onChange={() => onToggle(participant.userId)}
              />
              {participant.isChecked && <span className="headline-4 me-3">{expenseDetail.currency}</span>}
              {participant.isChecked && (
                <input
                  onBlur={onBlur}
                  className={styles.amount}
                  essence-input="true"
                  value={participant.amount}
                  onChange={(e) => onAmountChange(e, participant.userId)}
                  type="tel"
                ></input>
              )}
            </li>
          ))}
          {form.isDisabled && (
            <p className={styles.message}>
              Note: Remaining balance to match{" "}
              {(expenseDetail.amount - creditAmount).toFixed(2)}
            </p>
          )}
          <EssenceAnimatedButton
            onClick={onSaveExpense}
            name="save"
            disabled={form.isDisabled}
          />
        </ul>
      </Content>
    </>
  );
};

export default Participants;
