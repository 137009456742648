import api from "./api";

export const refreshTokenMutation = async () => ({
  mutationFn: async (fcmToken) =>
    await api
      .put(`/api/account/refreshToken`, { fcmToken })
      .then((res) => res.data),
});


