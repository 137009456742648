import React from 'react'
import Logo from '../logo/page'

export default function LeftPane({ title, subtitle }) {
    return (
        <div className="hidden md:flex items-center justify-center flex-col h-screen">
            <Logo size='120px' />
            <h1 className="mt-10 text-5xl">{title}</h1>
            <h4 className="mt-2">{subtitle}</h4>
        </div>
    )
};