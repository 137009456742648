import styles from "./todo.module.css";
import { Reorder, useDragControls } from "framer-motion";
import { ReorderIcon } from "../../../../components/essence/checkbox/render-icon";
import EssenceCheckbox from "../../../../components/essence/checkbox/checkbox";
import Animated from "../../../../components/essence/animated/animated";

export default function TodoRow({ todo, onToggle, onRemove, onDragEnd }) {
  const dragControls = useDragControls();
  const noAnimation = {
    initial: {},
    animate: {},
    exit: {},
  };

  return (
    <Reorder.Item
      key={todo.toDoId}
      value={todo}
      dragListener={false}
      dragControls={dragControls}
      onDragEnd={onDragEnd}
      variants={noAnimation}
    >
      <Animated>
        <div className="flex items-center">
          <span className="md:mr-10 mr-4 cursor-move">
            <ReorderIcon dragControls={dragControls} />
          </span>
          <div className="w-full">
            <EssenceCheckbox
              className={`${
                todo.status === "COMPLETED" && styles["is-checked"]
              }`}
              title={todo.name}
              checked={todo.status === "COMPLETED"}
              checkBoxColor={todo.status === "COMPLETED" && "gray"}
              onChange={() => onToggle(todo.toDoId)}
              actionIcon="fa fa-trash"
              onActionClick={() => onRemove(todo.toDoId)}
            />
          </div>
        </div>
      </Animated>
    </Reorder.Item>
  );
}
