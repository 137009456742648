import EssenceButton from "../button/button";
import styles from "./alert.module.css";

export default function EssenceAlert({
  onOk,
  onDismiss,
  message,
  okButton,
  dismissButton,
  ...props
}) {
  return (
    <>
      <div className={styles.overlay} />
      <div className={styles.alert}>
        <div className={styles["txt-message"]}>{message}</div>
        <div className={styles["buttons-container"]}>
          {dismissButton && (
            <EssenceButton
              {...props}
              name={dismissButton}
              onClick={onDismiss}
            />
          )}
          {okButton && (
            <EssenceButton {...props} name={okButton} onClick={onOk} />
          )}
        </div>
      </div>
    </>
  );
}
