import { Contacts } from "@capacitor-community/contacts";
import { Capacitor } from "@capacitor/core";
import parsePhoneNumber from "libphonenumber-js";
import api from "../../shared/services/api";
import { get, set } from "idb-keyval";
// import { v4 as uuid } from "uuid";
// import { faker } from "@faker-js/faker";

var contactsByPhoneNumberMap = {};
var contactsByIdMap = {};
var storedContacts = [];

export const keys = {
  ARRAY_ALL_CONTACTS: "ARRAY_ALL_CONTACTS",
  ARRAY_LOCAL_CONTACTS: "ARRAY_LOCAL_CONTACTS",
  MAP_CIRCLE_PARTICIPANTS: "MAP_CIRCLE_PARTICIPANTS",
  ARRAY_SERVER_CONTACTS: "ARRAY_SERVER_CONTACTS",
  MAP_PHONE_NUMBER: "MAP_PHONE_NUMBER",
  MAP_CONTACTS_ID: "MAP_CONTACTS_ID",
};
get(keys.MAP_PHONE_NUMBER).then(
  (map) => (contactsByPhoneNumberMap = map ?? {})
);

const _syncContactsWithServer = async (upsertContacts, deletedContacts) => {
  return await api.patch(`/api/Account/Contact`, {
    upsert: upsertContacts,
    delete: deletedContacts,
  });
};

const getContactsFromServer = async () => {
  const response = await api.get(`api/Account/Contact`);
  await set(keys.ARRAY_SERVER_CONTACTS, response.data);
  _makeUnion();
};

const getStoredContactsMap = async () => {
  if (Object.keys(contactsByPhoneNumberMap).length === 0) {
    contactsByPhoneNumberMap = await get(keys.MAP_PHONE_NUMBER);
  }
  return contactsByPhoneNumberMap;
};

const getStoredContactsArray = async () => {
  if (storedContacts.length === 0) {
    storedContacts = await get(keys.ARRAY_ALL_CONTACTS);
  }
  return storedContacts;
};

const getContactDetail = ({ countryCode, phoneNumber }) => {
  return contactsByPhoneNumberMap[`${countryCode}${phoneNumber}`];
};

const getParticipant = (contact) => {
  const phoneNumberContact =
    contactsByPhoneNumberMap[`${contact.countryCode}${contact.phoneNumber}`];
  if (phoneNumberContact) {
    return {
      ...phoneNumberContact,
      userId: phoneNumberContact.userId
        ? phoneNumberContact.userId
        : contact.userId,
    };
  }

  const idContact = contactsByIdMap[contact.userId];
  if (idContact) {
    return {
      ...idContact,
      userId: idContact.userId ? idContact.userId : contact.userId,
    };
  }
  return contact;
};

const syncContacts = async () => {
  let retrievedContacts;

  if (Capacitor.isNativePlatform() && Capacitor.isPluginAvailable("Contacts")) {
    const projection = {
      // Specify which fields should be retrieved.
      name: true,
      phones: true,
    };

    // Convert the projection to an array of phone numbers
    const { contacts } = await Contacts.getContacts({
      projection,
    });

    var uniqueContacts = [];
    // const response = await fetch("https://ipapi.co/json/");
    // const data = await response.json();

    contacts.forEach((contact) => {
      const formattedPhones = contact.phones;
      formattedPhones?.forEach((phone) => {
        const updatedPhone = phone.number.startsWith("+")
          ? parsePhoneNumber(phone.number).nationalNumber.replace(/\D/g, "")
          : phone.number.replace(/\D/g, "");
        const countryCode = `${
          parsePhoneNumber(phone.number, "IN")?.countryCallingCode
        }`;
        const existingContact = uniqueContacts.find(
          (existing) => existing.phoneNumber === updatedPhone
        );
        if (!existingContact) {
          uniqueContacts.push({
            name: contact.name?.display || "(No Name)",
            phoneNumber: updatedPhone, // Each object has a single phone number
            countryCode: countryCode,
          });
        } else if (
          contact.name &&
          existingContact.name != contact.name.display
        ) {
          // If there are multiple contacts with same phone but different names, append the names
          existingContact.name = `${existingContact.name}, ${contact.name.display}`;
        }
      });
    });

    retrievedContacts = uniqueContacts.sort((a, b) =>
      (a.name?.toLocaleLowerCase() || "").localeCompare(
        b.name?.toLocaleLowerCase() || ""
      )
    );

    const storedContacts = await get(keys.ARRAY_LOCAL_CONTACTS);
    var createdContacts = [];
    const updatedContacts = [];
    const deletedContacts = [];

    if (storedContacts && storedContacts.length > 0) {
      // Take two index/counters to keep track of stored vs retrieved contacts.
      var storedIndex = 0;
      var retrievedIndex = 0;

      while (retrievedIndex < retrievedContacts.length) {
        // Get the contact from the CB
        const retrievedContact = retrievedContacts[retrievedIndex];

        // Get the contact from the local storage
        const storedContact = storedContacts[storedIndex];

        if (
          retrievedContact.name === storedContact.name &&
          retrievedContact.phoneNumber == storedContact.phoneNumber
        ) {
          // If the contacts are same simply increment the index
          storedIndex++;
          retrievedIndex++;
        } else if (
          retrievedContact.name !== storedContact.name &&
          retrievedContact.phoneNumber == storedContact.phoneNumber
        ) {
          // If the contact name has updated but not the phone number,
          // update it on the server if present in the userId mapping
          updatedContacts.push({ ...retrievedContact });
          storedIndex++;
          retrievedIndex++;
        } else if (
          retrievedContact.name === storedContact.name &&
          retrievedContact.phoneNumber !== storedContact.phoneNumber
        ) {
          // If the contact name has updated but not the phone number,
          // update it on the server if present in the userId mapping
          createdContacts.push({ ...retrievedContact });
          storedIndex++;
          retrievedIndex++;
        } else if (retrievedContact.name > storedContact.name) {
          // Some contact was deleted from the contact book.
          deletedContacts.push({ ...retrievedContact });
          storedIndex++;
        } else if (retrievedContact.name < storedContact.name) {
          // Some contact was created int the contact book.
          createdContacts.push({ ...retrievedContact });
          retrievedIndex++;
        }
      }
    } else {
      createdContacts = retrievedContacts;
    }

    if (
      createdContacts.length > 0 ||
      updatedContacts.length > 0 ||
      deletedContacts.length > 0
    ) {
      _syncContactsWithServer(
        [...createdContacts, ...updatedContacts],
        deletedContacts
      ).then(async (response) => {
        if (response.data) {
          const serverContacts = response.data;
          const processedContacts = retrievedContacts.map((contact) => {
            const matchedContact = serverContacts.find(
              (serverContact) =>
                serverContact.phoneNumber === contact.phoneNumber &&
                serverContact.countryCode.toString() === contact.countryCode
            );

            return matchedContact
              ? {
                  ...contact,
                  userId: matchedContact?.userId,
                }
              : {
                  ...contact,
                };
          });
          await set(keys.ARRAY_LOCAL_CONTACTS, processedContacts);
          await _makeUnion();
        }
      });
    }
  } else {
    return Promise.resolve([]);
  }
};

// const _getFakeContacts = () => {
//   const contacts = [];
//   for (let i = 0; i < 1; i++) {
//     let name = faker.person.fullName();
//     let phone = faker.phone.number();
//     let contact = {
//       name,
//       phoneNumber: "8903283752", //phone.replace(/\D/g, ""),
//       countryCode: 91,
//       userId: uuid(),
//     };
//     contacts.push(contact);
//   }

//   for (let i = 0; i < 10; i++) {
//     let name = faker.person.fullName();
//     let phone = faker.phone.number();
//     let contact = {
//       name,
//       phoneNumber: phone.replace(/\D/g, ""),
//       countryCode: 91,
//       userId: uuid(),
//     };
//     contacts.push(contact);
//   }
//   return Promise.resolve(contacts);
// };

// _getFakeContacts().then(async (contacts) => {
//   const existingContacts = (await get(keys.ARRAY_LOCAL_CONTACTS)) ?? [];

//   await set(keys.ARRAY_LOCAL_CONTACTS, [...existingContacts, ...contacts]);

//   await _makeUnion();
// });

// // It makes a union of the 'server contacts' & 'local contacts'
// // The 'local contacts' take a priority over server contacts when merging.

const addParticipants = async (circleId, participants) => {
  const circleParticipants = (await get(keys.MAP_CIRCLE_PARTICIPANTS)) ?? {};
  const existingCircleParticipants = circleParticipants[circleId];
  const filteredParticipants =
    existingCircleParticipants?.filter(
      (existingCircleParticipant) =>
        participants.filter(
          (participant) =>
            participant.userId === existingCircleParticipant.userId
        ).length === 0
    ) ?? [];
  circleParticipants[circleId] = [...participants, ...filteredParticipants];
  await set(keys.MAP_CIRCLE_PARTICIPANTS, circleParticipants);
  await _makeUnion();
};

const _makeUnion = async () => {
  const localContacts = (await get(keys.ARRAY_LOCAL_CONTACTS)) ?? [];
  const serverContacts = (await get(keys.ARRAY_SERVER_CONTACTS)) ?? [];
  const circleParticipantsMap = (await get(keys.MAP_CIRCLE_PARTICIPANTS)) ?? {};

  // Get the maps from storage if empty
  if (Object.keys(contactsByPhoneNumberMap).length === 0) {
    contactsByPhoneNumberMap = (await get(keys.MAP_PHONE_NUMBER)) ?? {};
    contactsByIdMap = (await get(keys.MAP_CONTACTS_ID)) ?? {};
  }

  const circleIds = Object.keys(circleParticipantsMap);
  // Update the map with circle participants
  if (circleIds.length > 0) {
    for (let index = 0; index < circleIds.length; index++) {
      const circleId = circleIds[index];
      const circleParticipants = circleParticipantsMap[circleId];

      // Update the maps with the new details.
      for (let index = 0; index < circleParticipants.length; index++) {
        const contact = circleParticipants[index];
        if (contact.phoneNumber && contact.phoneNumber.trim().length > 0) {
          const phoneNumber = `${contact.countryCode}${contact.phoneNumber}`;
          contactsByPhoneNumberMap[phoneNumber] = contact;
        }
        contactsByIdMap[contact.userId] = contact;
      }
    }
  }

  // Update the maps with the new details.
  for (let index = 0; index < serverContacts.length; index++) {
    const contact = serverContacts[index];
    if (contact.phoneNumber && contact.phoneNumber.trim().length > 0) {
      const phoneNumber = `${contact.countryCode}${contact.phoneNumber}`;
      contactsByPhoneNumberMap[phoneNumber] = contact;
    }
    contactsByIdMap[contact.userId] = contact;
  }

  // Update the maps with the new details.
  for (let index = 0; index < localContacts.length; index++) {
    const contact = localContacts[index];
    if (contact.phoneNumber && contact.phoneNumber.trim().length > 0) {
      const phoneNumber = `${contact.countryCode}${contact.phoneNumber}`;
      contactsByPhoneNumberMap[phoneNumber] = contact;
    }

    if (contact.userId) {
      contactsByIdMap[contact.userId] = contact;
    }
  }

  let storedContacts = Object.values(contactsByPhoneNumberMap);

  // Sort the storedContacts only if there are new contacts in storedContacts
  storedContacts = storedContacts.sort((a, b) =>
    (a.name?.toLocaleLowerCase() || "").localeCompare(
      b.name?.toLocaleLowerCase() || ""
    )
  );

  await set(keys.MAP_PHONE_NUMBER, contactsByPhoneNumberMap);
  await set(keys.MAP_CONTACTS_ID, contactsByIdMap);
  await set(keys.ARRAY_ALL_CONTACTS, storedContacts);
};

export default {
  syncContacts,
  getContactsFromServer,
  getStoredContactsMap,
  getStoredContactsArray,
  addParticipants,
  getParticipant,
};
