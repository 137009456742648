"use client";
import React, { useEffect } from "react";
import EssenceAnimatedCheckbox from "../../../components/essence/checkbox/animated-checkbox";
import EssenceButton from "../../../components/essence/button/button";
import Header from "../../../shared/components/header/page";
import Content from "../../../shared/components/content/page";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import styles from "./page.module.css";
import { useCircleMutations } from "../../../shared/tanstack/circle/mutations";
import { useMutation } from "@tanstack/react-query";

export default function CircleInterests() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { addCircleMutation } = useCircleMutations({});
  const { mutate: addCircle, isSuccess } = useMutation(addCircleMutation);
  const { state } = useLocation();
  const slider = state?.context === "Toggle_Module";

  const interests = [
    {
      displayName: "Moments",
      value: "moments",
      disabled: true,
    },
    {
      displayName: "Gallery",
      value: "gallery",
      disabled: true,
    },
    {
      displayName: "To Do",
      value: "todo",
      disabled: false,
    },
  ];
  const familyFriend = [
    {
      displayName: "Chat",
      value: "chat",
      disabled: true,
    },
    ...interests,
  ];
  const travel = [
    {
      displayName: "Chat",
      value: "chat",
      disabled: true,
    },
    {
      displayName: "Expenses",
      value: "expenses",
      disabled: false,
    },
    {
      displayName: "Itinerary",
      value: "itinerary",
      disabled: false,
    },
    ...interests,
  ];
  const work = [
    {
      displayName: "Announcements",
      value: "announcements",
      disabled: true,
    },
    ...interests,
  ];

  const onChange = (e) => {
    var interests = searchParams.get("interests")
      ? searchParams.get("interests").split(",")
      : [];
    let isChecked = e.target.checked;

    if (isChecked) {
      if (interests.findIndex((x) => x === e.target.value) < 0) {
        interests.push(e.target.value);
      }
    } else {
      interests = interests.filter((x) => x !== e.target.value);
    }

    searchParams.set("interests", interests);
    setSearchParams(searchParams);
  };

  const circleType = searchParams.get("type");
  let selectedType;

  switch (circleType) {
    case "family":
      selectedType = familyFriend;
      break;
    case "friends":
      selectedType = familyFriend;
      break;
    case "travel":
      selectedType = travel;
      break;
    case "work":
      selectedType = work;
      break;
    default:
      selectedType = interests;
      break;
  }

  useEffect(() => {
    if (isSuccess) {
      navigate("/secure/circles", { replace: true });
    }
  }, [isSuccess]);

  const submitForm = async (e) => {
    // We don't want the page to refresh
    e.preventDefault();

    addCircle({
      interests: searchParams.get("interests").split(","),
      type: circleType,
      name: searchParams.get("name"),
    });
  };

  return (
    <div>
      <Header
        title="Interests"
        subtitle="Based on your circle we've pre-selected some values for you."
      />

      <Content>
        <form onSubmit={submitForm}>
          {selectedType.map((interest) => {
            return (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <EssenceAnimatedCheckbox
                  title={interest.displayName}
                  name="interest-checkbox-group"
                  value={interest.value}
                  disabled={interest.disabled}
                  key={interest.value}
                  onChange={onChange}
                />
                {slider && (
                  <label>
                  {/* <label className={styles["toggle-slider"]}> */}
                    <input type="checkbox" />
                    {/* <span className={styles.slider}></span> */}
                    <span></span>
                  </label>
                )}
              </div>
            );
          })}
          <div className="mb-16" />
          <EssenceButton type="submit" name="create" />
        </form>
      </Content>
    </div>
  );
}
